import React from 'react'
import { useUserAuth } from '../context/userAuthContext'
import { FaCircleUser } from "react-icons/fa6";


const User = () => {
  const {user, val} = useUserAuth()
  console.log(user.accessToken);
  return (
      <div id='user' className='font-Poppins pb-10 overflow-auto'>
      <h1 className='py-4 text-[20px] font-Roboto'>Users</h1>
            <table className='w-full'>
              <thead className='bg-gray-100'>
                <tr>
                  <th className='text-left font-normal p-2 text-sm'>Image</th>
                  <th className='text-left font-normal p-2 text-sm'>Name</th>
                  <th className='text-left font-normal p-2 text-sm'>Email</th>
                  <th className='text-left font-normal p-2 text-sm'>Phone</th>
                </tr>
              </thead>
              {val.map(values => (
              <tbody>
                <tr className='overflow-auto'>
                  <td className='py-3 px-2 text-sm'>{user.photoURL ? <img src={user.photoURL} alt='user photo' className='w-[80px] rounded-full'/> : <FaCircleUser className='text-[20px]'/>}</td>
                  <td className='py-3 px-2 text-sm whitespace-nowrap'><h1 className='capitalize'>{values?.name || values.Name || user.displayName}</h1></td>
                  <td className='py-3 px-2 text-sm'><h1>{values.email || user.email}</h1></td>
                  <td className='py-3 px-2 text-sm'><h1>{values.phone}</h1></td>
                </tr>
              </tbody>
              ))}
            </table>    
    </div>
  )
}

export default User