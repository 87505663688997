import React, { useRef, useState } from 'react'
import about from '../images/about.png'
import career from '../images/career.png'
import { motion, scroll } from 'framer-motion'
import { ToastContainer, toast } from 'react-toastify'
import emailjs from 'emailjs-com'



const Logistics = () => {
  const [details, setDetails] = useState({
    address: '',
    email: '',
    phone: '',
    message: ''
})
const [error, setError] = useState('')
const form = useRef();
const sendEmail = (e) => {
  e.preventDefault();
  if(!details.address || !details.phone || !details.email || !details.message){
    setError('All fields are required')
    toast.error('All fields are required')
}else{
  emailjs.sendForm('service_w8b6iot', 'template_djne3ii', form.current, 'lPlwBbEcx2sMs63c3')
  .then((result) => {
      console.log(result.text)
      toast.success('Message sent successfully')
      setDetails({
        message: (''),
        address: (''),
        email: (''),
        phone: ('')
      })
  }, (error) => {
      console.log(error.text);
  });
   
}};

const handleChange = (e) => {
  setDetails(prev => ({...prev, [e.target.id]:e.target.value}))
}


  return (
    <motion.div initial ={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} transition={{duration:2}}  className=''>
      <div className="slider xl:h-[80vh] lg:h-[80vh] h-[40vh] flex flex-col justify-center">
        <div className='slide flex items-center xl:text-[5rem] xl:px-[3rem] lg:px-[3rem] px-[1rem] lg:text-6xl text-3xl text-left text-white  font-Heading'><h1>The perfect<br />shipment lives here</h1></div>
        <div className="slide flex items-center xl:text-[5rem] xl:px-[3rem] lg:px-[3rem] px-[1rem] lg:text-6xl text-3xl text-left text-white  font-Heading gap-2"><h1>We make transport <br /><span className=''>fast</span> and <span className=''>safe</span></h1></div>
        <div className="slide flex items-center xl:text-[5rem] xl:px-[3rem] lg:px-[3rem] px-[1rem] lg:text-6xl text-3xl text-left text-white  font-Heading"><h1>Logistics solution <br /> around the world</h1></div>
        <div className="slide flex items-center xl:text-[5rem] xl:px-[3rem] lg:px-[3rem] px-[1rem] lg:text-6xl text-3xl text-left text-white  font-Heading"><h1>Your gateway to <br /> any destination in the world</h1></div>
        <div className="slide flex items-center xl:text-[5rem] xl:px-[3rem] lg:px-[3rem] px-[1rem] lg:text-6xl text-3xl text-left text-white  font-Heading"><h1>Your product is in <br /> good hands</h1></div>
        <h1 className='text-left text-4xl text-TEXT font-Poppins xl:text-[5rem] lg:text-6xl xl:px-[3rem] lg:px-[3rem] px-[1rem]'>Mecs Daedals<br /> Logistics</h1>
        <p className='text-white xl:text-xl lg:text-xl text-[15px] text-left font-Heading xl:px-[3rem] lg:px-[3rem] px-[1rem] py-4 xl:w-[50%] lg:w-[50%]'>We stand as a beacon of excellence in the logistics industry, providing unparalleled services in freight management and as a distinguished Amazon Delivery Service Partner (DSP)</p>
      </div>
        <aside className=''>
          <div className='font-Poppins py-6 flex items-center justify-center w-full xl:flex-nowrap lg:flex-nowrap flex-wrap bg-gray-100 px-[1rem] xl:px-[3rem] lg:px-[3rem]' id='about'>
            <div>
              <h1 className='text-[22px] pb-2 font-Heading text-TEXT'>about us</h1>
              <p className='leading-[1.9rem] text-TEXT'>Mecs Daedal Canada Inc., <span className='text-black'>we stand as a beacon of excellence in the logistics industry, providing unparalleled services in freight management and as a distinguished Amazon Delivery Service Partner (DSP). With a dedicated team of over 90 skilled professionals, we are committed to delivering seamless logistics solutions and exceptional Amazon delivery experiences for the community of Winnipeg, Manitoba and the rest of Canada.</span></p>
            </div>
            <img src={about} alt="" className='w-[700px] mt-8 xl:mt-0 lg:mt-0 sm:mt-6'/>
          </div>
          <div className='py-8 px-[1rem] xl:px-[3rem] lg:px-[3rem]  w-full' id='mission'>
            <div className='flex items-center text-center justify-center flex-col'>
            <h1 className='text-[22px] pb-2 font-Heading text-TEXT'>Our Mission Statement:</h1>
            <p className='leading-[1.9rem] xl:w-[50%] font-Poppins font-bold'>Empowering Connections, Delivering Excellence.<br /><span className='bg-white text-black font-normal'>We are driven by a mission to create seamless connections in the world of logistics, ensuring businesses thrive and communities prosper. We are dedicated to delivering excellence through our services, fostering trust, and building lasting partnerships with our clients and stakeholders.</span></p>
            </div>
            {/* <img src={mission} alt="" className='w-[450px] mt-8 xl:mt-0 lg:mt-0 sm:mt-6'/> */}
          </div>
          <div className='flex items-center justify-center w-full flex-col py-4 px-[1rem] xl:px-[3rem] lg:px-[3rem]'>  
            <h1 className='text-[22px] pb-2 font-Heading text-TEXT'>Our Goal:</h1>
            <p className='leading-[1.7rem] font-Poppins text-center xl:w-[60%] lg:[70%] w-[90%]'>At Mecs Daedal Canada Inc., our goal is to redefine the logistics landscape. We aim to consistently provide innovative, reliable, and cost-effective solutions that exceed our clients' expectations. By leveraging cutting-edge technology and a highly skilled workforce, we aspire to be the preferred logistics partner, setting new standards of efficiency and reliability in the industry.</p>
            <div className='flex items-center justify-center gap-[2rem] w-full xl:flex-nowrap lg:flex-wrap flex-wrap py-7'>
            <div className='py-4 xl:w-[30%] lg:w-[30%] bg-gray-100 p-4 border-dotted border-2' id='goal'>
              <h1 className='text-[20px] pb-2 font-Heading text-TEXT'>Customer-Centric Approach:</h1>
              <p className='leading-[1.7rem] font-Poppins'>We prioritize our customers' needs and preferences. Our dedicated customer support team is available to assist you with any inquiries or concerns you may have. Your satisfaction is our priority, and we go the extra mile to meet your expectations.</p>
            </div>
            <div className='py-4 xl:w-[30%] lg:w-[30%] bg-gray-100 p-4 border-dotted border-2' id='goal'>
              <h1 className='text-[20px] pb-2 font-Heading text-TEXT'>Comprehensive Range of Services:</h1>
              <p className='leading-[1.7rem] font-Poppins'>Whether you are a small business owner, an e-commerce seller, or an individual customer, we offer a wide range of delivery services tailored to meet your specific requirements. From standard deliveries to specialized services, we have you covered.</p>
            </div>
            </div>
          </div>
          <div className='px-[1rem] xl:px-[3rem] lg:px-[3rem] flex items-center justify-center flex-col py-10'>
            <h1 className='text-[20px] pb-2 font-Heading text-TEXT'>Career Opportunities:</h1>
            <h1 className='text-[16px] pb-2 font-Poppins text-BG text-center'>Join Our Team and Shape the Future of Logistics!</h1>
            <p className='leading-[1.7rem] font-Poppins text-center xl:w-[60%] lg:[70%] w-[90%]'>At Mecs Daedal Canada Inc., we recognize that our success is driven by the talent and dedication of our employees. We are always on the lookout for passionate individuals who are eager to contribute to our dynamic team. By joining us, you will become part of a company that values creativity, teamwork, and continuous growth.</p>
            <div className='flex justify-center flex-col py-10'>
              <h1 className='text-[20px] text-center text-TEXT font-Heading'>Why Choose a Career with Us?</h1>
              <div className='font-Poppins flex justify-center'>
                <ul className='flex justify-center py-8 gap-6 text-center xl:flex-wrap lg:flex-wrap flex-wrap w-[80%] xl:w-auto lg:w-auto'>
                  <li className='w-[400px] border-2 border-dashed p-4 border-TEXT'>
                  <h5 className='text-TEXT pb-2'>Professional Development:</h5>
                    We invest in the growth of our employees. Through training programs and mentorship, we help you enhance your skills and expertise, enabling you to excel in your career.
                  </li>
                  <li className='w-[400px] border-2 border-dashed p-4 border-TEXT'>
                  <h5 className='text-TEXT pb-2'>Collaborative Work Environment:</h5>
                  Experience the power of teamwork in a collaborative and inclusive workplace. We foster an environment where ideas are valued, and employees are encouraged to contribute their unique perspectives.                  </li>
                  <li className='w-[400px] border-2 border-dashed p-4 border-TEXT'>
                  <h5 className='text-TEXT pb-2'>Innovation and Technology</h5>
                  Embrace the opportunity to work with cutting-edge technology and innovative solutions. We are at the forefront of the logistics industry, providing you with a platform to learn, innovate, and make a significant impact.
                  </li>
                  <li className='w-[400px] border-2 border-dashed p-4 border-TEXT'>
                  <h5 className='text-TEXT pb-2'>Competitive Benefits:</h5>
                  We offer competitive compensation packages, comprehensive benefits, and a range of perks that recognize and reward your contributions to our success.
                  </li>
                  <li className='w-[400px] border-2 border-dashed p-4 border-TEXT'>
                  <h5 className='text-TEXT pb-2'>Community Engagement:</h5>
                  Be part of a company that cares about the community. We actively engage in local initiatives and charitable causes, empowering you to contribute to meaningful social impact.
                  </li>
                </ul>
              </div>
            </div>
            <div className='flex justify-center items-center flex-wrap xl:flex-nowrap lg:flex-nowrap'>
              <div className='flex justify-center flex-col'>
              <h1 className='text-TEXT text-[18px] pb-2 font-Heading'>Join Us in Shaping the Future:</h1>
              <p className='font-Poppins xl:w-[60%] lg:w-[50%]'>Are you ready to embark on a fulfilling career journey with Mecs Daedal Canada Inc.? <br />Explore our current job openings and start your exciting career in logistics and Amazon delivery services. <br />Together, let's create a future where logistics is seamless, and deliveries are exceptional.</p>
              </div>
              <img src={career} alt="" className='w-[400px]'/>
            </div>
          </div> 
          <div className='flex items-center justify-center py-8 flex-col font-Poppins w-full px-[1rem] xl:px-[3rem] lg:px-[3rem]'>
            <h1 className='text-[20px] text-TEXT font-Heading pb-3'>Contact Us</h1>
            <p className='text-center text-[17px]'>Ready to take the next step in your career? <br /><span>Contact Mecs Daedal Canada Inc. today!</span></p>
            <div className='flex items-center justify-center py-12 gap-10 xl:px-[7rem] lg:px-[4rem] flex-wrap xl:flex-nowrap lg:flex-nowrap w-full'>
            <form ref={form} className='border-[1px]  flex-col flex xl:p-8 lg:p-8 p-4 rounded-[4px] border-BG gap-4 xl:w-[70%] lg:w-[70%]'>
                <label htmlFor="address" className='flex flex-col'>Address:
                  <input type="text" placeholder='Your Address' id='address' name='subject' onChange={handleChange} className={`p-2 border-2 focus:border-TEXT focus:outline-none mt-2 rounded-[4px] ${error ? 'border-[#FF0000] transition ease-in-out 100ms' : ''}4`}/>
                </label>
                <label htmlFor="phone" className='flex flex-col'>
                  Phone Number:
                  <input type="phone" placeholder='Your Phone Number' id='phone' name='location' onChange={handleChange} className={`p-2 border-2 focus:border-TEXT focus:outline-none mt-2 rounded-[4px] ${error ? 'border-[#FF0000] transition ease-in-out 100ms' : ''}`}/>
                </label>
                <label htmlFor="email" className='flex flex-col'>
                Email Address:
                <input type="email" placeholder='Your Email Address' id='email' name='email' onChange={handleChange} className={`p-2 border-2 focus:border-TEXT focus:outline-none mt-2 rounded-[4px] ${error ? 'border-[#FF0000] transition ease-in-out 100ms' : ''}`}/>
                </label>
                <label htmlFor="message" className='flex flex-col'>
                Your Message:
                <textarea type="message" placeholder='Your Message here...' id='message' name='message' onChange={handleChange} className={`p-2 border-2 focus:border-TEXT focus:outline-none mt-2 rounded-[4px] resize-none ${error ? 'border-[#FF0000] transition ease-in-out 100ms' : ''}`}/>
                </label>
                {error && <p className='text-[#FF0000]'>All fields are required **</p>}
              <motion.button whileTap={{scale:1.1}} className='mt-10 uppercase text-white hover:bg-black bg-TEXT border-none p-3' onClick={sendEmail}>send message</motion.button>
            </form>
            <h1 className='font-Poppins text-center text-[18px]'>Join Mecs Daedal Canada Inc. and be part of a team that delivers excellence, fosters innovation, and shapes the future of logistics!</h1>
            </div>
          </div> 
        </aside>
        <ToastContainer/>
    </motion.div>
  )
}

export default Logistics