import React from 'react'

const ServiceData = [
    {
        title: 'Luxurious Homes and Apartments',
        details: 'Discover our portfolio of exquisite properties designed to cater to your every need. From spacious family homes to stylish apartments, each residence is meticulously curated, ensuring a perfect balance of comfort and sophistication.'
    },
    {
        title: 'Tailored for Families',
        details: 'We understand the importance of family time. Our properties are family-friendly, featuring ample space, fully equipped kitchens, and cozy living areas. Create cherished memories in a home-like atmosphere where every member of the family feels at ease.'
    },
    {
        title: 'Ideal for Business Travel',
        details: 'For corporate travelers, our accommodations provide a peaceful environment conducive to work and relaxation. High-speed Wi-Fi, ergonomic workspaces, and convenient locations near business hubs make our properties perfect for your business travel needs.'
    },
    {
        title: 'Personalized Airbnb Experience',
        details: 'Grand Castle Investments Ltd. offers a unique Airbnb experience where guests can immerse themselves in the local culture and lifestyle. Our properties are strategically located, allowing you to explore the city like a local while enjoying the comfort of a private residence.'
    },
    {
        title:  'Home Away from Home',
        details:    'Whether you are traveling for leisure or work, we provide a home away from home experience. Enjoy the freedom of having your own space, cooking your favorite meals, and unwinding in a cozy ambiance after a day of exploration or meetings.'
    },
    {
        title:  'Exceptional Hospitality',
        details:    'Our dedicated team is committed to ensuring your stay exceeds expectations. From seamless check-ins to personalized recommendations, we go the extra mile to make your experience with us unforgettable.'
    }
]

const ServiceList = () => {
  return (
    <div className='px-[1rem] xl:px-[3rem] lg:px-[3rem] font-Poppins py-[4rem] flex text-center justify-center flex-col'>
        <h1 className='text-[25px] pb-5 text-TEXT font-Heading capitalize'>Our Distinctive Offerings</h1>
        <div className='w-auto overflow-scroll px-[35px] scrollbar-hide'>
            <div className='flex  justify-center my-[2rem] xl:gap-6 lg:gap-6 gap-4  xl:flex-nowrap lg:flex-nowrap w-fit'>
                {ServiceData.map((item,index) => (
                    <div key={index} className='flex justify-center flex-col xl:w-[300px] lg:w-[300px] sm:w-[300px] w-[300px] h-[300px] gap-3 p-3 text-center' id='services'>
                        {/* <img src={item.img} alt='' /> */}
                        <h1 className='text-[17px] text-TEXT uppercase font-Poppins'>{item.title}</h1>
                        <p className='text-[14px]'>{item.details}</p>
                    </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default ServiceList