import { createContext, useContext, useState } from "react";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    signInWithPopup,
    GoogleAuthProvider,
} from 'firebase/auth'
import {auth, database} from '../components/firebase'
import { useEffect } from "react";
import RoomDetails from "../components/RoomDetails";
import { format } from 'date-fns'
import { addDoc, collection, doc, getDocs } from 'firebase/firestore';
import img1 from '../images/IMG_9745.jpg'
import img2 from '../images/IMG_9756.jpg'
import img3 from '../images/IMG_9757.jpg'
import img4 from '../images/IMG_9758.jpg'
import img5 from '../images/IMG_9765.jpg'
import img6 from '../images/IMG_9272.jpg'
import img7 from '../images/IMG_9275.jpg'
import img8 from '../images/IMG_9277.jpg'
import img9 from '../images/IMG_9279.jpg'
import img10 from '../images/IMG_9283.jpg'
import img11 from '../images/IMG_9241.jpg'
import img12 from '../images/IMG_9244.jpg'
import img13 from '../images/IMG_9233.jpg'
import img14 from '../images/IMG_9235.jpg'
import img15 from '../images/IMG_9212.jpg'
import img16 from '../images/IMG_9216.jpg'
import img17 from '../images/IMG_9223.jpg'
import img18 from '../images/IMG_9225.jpg'
import img19 from '../images/IMG_9228.jpg'
import img20 from '../images/IMG_9698.jpg'
import img21 from '../images/IMG_9712.jpg'
import img22 from '../images/IMG_9701.jpg'
import img23 from '../images/IMG_9708.jpg'
import img24 from '../images/IMG_9709.jpg'
import img25 from '../images/IMG_9714.jpg'
import img26 from '../images/IMG_9169.jpg'
import img27 from '../images/IMG_9170.jpg'
import img28 from '../images/IMG_9174.jpg'
import img29 from '../images/IMG_9177.jpg'
import img30 from '../images/IMG_9182.jpg'


const UserAuthContext = createContext()

export function UserAuthContextProvider({children}) {
    const [user, setUser] = useState('')
    const [apartment, setApartment] = useState([])  
    const [searchInput, setSearchInput] = useState('')
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [numberOfGuest, setNumberOfGuest] = useState(1)
    const [status, setStatus] = useState(null)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [phone, setPhone] = useState('')
    const [fullName, setFullName] = useState('')

    const value = collection(database, 'users')
    const [val, setVal] = useState([])
    const orders = collection(database, 'bookings')
    const [bookings, setBookings] = useState([])

    useEffect(() => {
        const getUsers = async () => {
            const dbVal = await getDocs(value)
            setVal(dbVal.docs.map(docs => ({...docs.data(), id:doc.id})))
        }
        getUsers()
    },[])

    useEffect(() => {
        const getBookings = async () => {
            const dbVal = await getDocs(orders)
            setBookings(dbVal.docs.map(docs => ({...docs.data(), id:doc.id})))
        }
        getBookings()
    },[])

    const handleCreate = async () => {
        await addDoc(value, {name: fullName, email:email, phone:phone})
    }


    const Apartments = [
        {
        id: 1,
        title: 'Beautiful Luxury Home',
        location: 'canada',
        price: '135',
        images: [
            img1,
            img2,
            img3,
            img4,
            img5
        ],
        reviews: [
            
        ],
        averageRating: 4.8,
        category: '3-bedroom',
        city: 'edmonton',
        status: status
       },
       {
        id: 2,
        title: 'mordern two storey beautiful home',
        location: 'canada',
        price: '135',
        images: [
            
            img6,
            img7,
            img8,
            img9,
            img10
        ],
        reviews: [
           
        ],
        averageRating: 4.8,
        category: '3-bedroom',
        city: 'edmonton',
        status: status
       },
       {
        id: 3,
       title: 'newly built and furnished',
       location: 'canada',
       price: '130',
       images: [
           img11,
           img12,
           img13,
           img14
       ],
       reviews: [
        
    ],
    averageRating: 4.8,
    category: 'mini-flat',
    city: 'edmonton',
    status: status 
    }, 
    {
        id: 4,
        title: 'Luxury and comfort combine',
        location: 'canada',
        price: '150',
        images: [
            img15,
            img16,
            img17,
            img18,
            img19
        ],
        reviews: [
            
        ],
        averageRating: 4.8,
        category: '3-bedroom',
        city: 'edmonton',
        status: status
    },
    {
    id: 5,
    title: 'cozy and unique ravine suite',
    location: 'canada',
    price: '135',
    images: [
        img20,
        img21,
        img22,
        img23,
        img24,
        img25,
    ],
    reviews: [
        
    ],
    averageRating: 4.8,
    category: 'executive',
    city: 'Edmonton'  ,
    status: status
    },
    {
        id: 6,
        title: 'serenity villa of style and love',
        location: 'canada',
        price: '135',
        images: [
            img26,
            img27,
            img28,
            img29,
            img30
        ],
        reviews: [
            
        ],
        averageRating: 4.8,
        category: '3-bedroom',
        city: 'Edmonton',
        status: status
    }
    ]
    

    const selectionRange = {
        startDate: startDate,
        endDate: endDate,
        key: 'selection'
    }
    const newDate = (new Date(startDate))
    const oldDate = (new Date(endDate))
    const difference = oldDate - newDate
    const dateDifference = Math.floor(difference / (1000 * 60 * 60 * 24))

    const handleSelect = (ranges) => {
        setStartDate(ranges.selection.startDate)
        setEndDate(ranges.selection.endDate)
    }
   
    const handleSearch = (e) => {
        setSearchInput(e.target.value)
    }
    const cancelSearch = () => {
        setSearchInput('')
    }
    const formattedStartDate = format(new Date(startDate), 'dd MMMM yyyy')
    const formattedEndDate = format(new Date(endDate), 'dd MMMM yyyy')
    const formattedDate = format (new Date(), 'dd MMMM yyyy')

     const toggleStatus = (getCurrentId) => {
        console.log(getCurrentId);
        if(formattedEndDate <= formattedDate){
            setStatus(true)
        } else{
            setStatus(false)
        }
     }

    const range = `${formattedStartDate} to ${formattedEndDate}`
    

    const filteredItems = Apartments.filter(Apartment => Apartment.location.toLocaleLowerCase().indexOf(searchInput.toLocaleLowerCase()==-1) )
    function filteredData (Apartments, searchInput) {
        let filteredApartment = Apartments

        if(searchInput){
            filteredApartment = filteredApartment.filter(({title, location, category}) => category === searchInput || title === searchInput || location === searchInput)
        }

        return filteredApartment.map(({ title, location, price, images, id,reviews, city, averageRating, status}) => (
            <RoomDetails
            key={id}
            id={id}
            title={title}
            location={location}
            price={price}
            images={images}
            reviews={reviews}
            city={city}
            averageRating={averageRating}
            status={status}
            />
        ))
    }

    const result = filteredData(Apartments, searchInput)

    function register (email,password) {
        return createUserWithEmailAndPassword(auth, email, password)
    }
    function login  (email,password) {
        return signInWithEmailAndPassword(auth, email, password)
    }

    function logOut() {
        return signOut(auth)
    }

    function signInWithGoogle() {
        const googleAuthProvider = new GoogleAuthProvider()
        return signInWithPopup(auth, googleAuthProvider)
    }  
       

        useEffect(() => {
            const onChangeState = onAuthStateChanged(auth, (currentUser) => {
                setUser(currentUser)
            })
            return () => {
                onChangeState()
            }
        },[])
    

    return <UserAuthContext.Provider value={{
        login, 
        register, 
        user,
        logOut,
        signInWithGoogle, 
        apartment, 
        selectionRange, 
        handleSelect, 
        cancelSearch,
        searchInput, 
        endDate, 
        startDate,
        numberOfGuest, 
        setNumberOfGuest, 
        handleSearch, 
        setApartment, 
        setSearchInput,
        result,
        dateDifference,
        setSearchInput,
        formattedEndDate,
        formattedStartDate,
        range,
        startDate,
        endDate,
        status,
        setStatus,
        toggleStatus,
        email,
        fullName,
        phone,
        password,
        setPassword,
        setPhone,
        setEmail,
        setFullName,
        handleCreate,
        val,
        Apartments,
        orders,
        bookings,
        setBookings,
        formattedDate,
        
    }}>{children}</UserAuthContext.Provider>
}

    export function useUserAuth() {
        return useContext(UserAuthContext)
    }
