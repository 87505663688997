import React from 'react'
import { useUserAuth } from '../context/userAuthContext'

const Orders = () => {
  const {bookings} = useUserAuth()
 
  
  return (
    <div id='orders' className='font-Poppins pb-10 overflow-auto'>
    <h1 className='py-4 text-[20px] font-Roboto'>Bookings</h1>
          <table className='w-full'>
            <thead className='bg-TEXT text-white rounded-[3px]'>
              <tr>
                <th className='text-left font-normal p-2 xl:text-[17px] lg:text-[17px] text-[14px]'>Name</th>
                <th className='text-left font-normal p-2 xl:text-[17px] lg:text-[17px] text-[14px]'>Email</th>
                <th className='text-left font-normal p-2 xl:text-[17px] lg:text-[17px] text-[14px]'>Phone</th>
                <th className='text-left font-normal p-2 xl:text-[17px] lg:text-[17px] text-[14px]'>Apartment</th>
                <th className='text-left font-normal p-2 xl:text-[17px] lg:text-[17px] text-[14px]'>Start Date</th>
                <th className='text-left font-normal p-2 xl:text-[17px] lg:text-[17px] text-[14px]'>End Date</th>
                <th className='text-left font-normal p-2 xl:text-[17px] lg:text-[17px] text-[14px]'>Days</th>
                <th className='text-left font-normal p-2 xl:text-[17px] lg:text-[17px] text-[14px]'>Total Paid</th>
              </tr>
            </thead>
            {bookings.map(values => (
            <tbody key={values.email}>
              <tr className='overflow-auto'>
                <td className='py-3 px-2 text-sm xl:text-[16px] lg:text-[15px] whitespace-nowrap'><h1 className='capitalize'>{values.name}</h1></td>
                <td className='py-3 px-2 text-sm xl:text-[16px] lg:text-[15px]'><h1>{values.email}</h1></td>
                <td className='py-3 px-2 text-sm xl:text-[16px] lg:text-[15px]'><h1>{values.phone}</h1></td>
                <td className='py-3 px-2 text-sm xl:text-[16px] lg:text-[15px]'><h1>{values.apartment}</h1></td>
                <td className='py-3 px-2 text-sm xl:text-[16px] lg:text-[15px]'><h1>{values.start}</h1></td>
                <td className='py-3 px-2 text-sm xl:text-[16px] lg:text-[15px]'><h1>{values.end}</h1></td>
                <td className='py-3 px-2 text-sm xl:text-[16px] lg:text-[15px]'><h1>{values.days}</h1></td>
                <td className='py-3 px-2 text-sm xl:text-[16px] lg:text-[15px]'><h1>${values.total}</h1></td>
              </tr>
            </tbody>
            ))}
          </table>    
  </div>
  )
}

export default Orders