import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PaystackPop from '@paystack/inline-js'
import { useUserAuth } from '../../context/userAuthContext'
import { IoIosArrowBack } from "react-icons/io";
import { motion } from 'framer-motion'
import PayPal from './PayPal';


const Payment = () => {
    const navigate = useNavigate()
    const {user} = useUserAuth()
    const location = useLocation()
    const {images,TotalAccFee,rating,numberOfGuest, title, dateDifference,bookingDate, formattedStartDate, formattedEndDate, numberOfDays, price, TotalFee, id} = location.state
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phone, setPhone] = useState('')
    const [error, setError] = useState(null)
    const [amount, setAmount] = useState(TotalAccFee)
    const [checkout, setCheckout] = useState(false) 
    // const paywithpaystack = (e) => {
    //   e.preventDefault()
    //   if(!firstName || !lastName || !phone || !email){
    //     setError('All fields are required!')
    //   }
    //   const paystack = new PaystackPop() 
    //   paystack.newTransaction({
    //     key: 'pk_test_247098f43350810e646476e654f89b0acaf93efc',
    //     amount: (amount * 100) * 1072 ,
    //     email,
    //     phone,
    //     firstName,
    //     lastName,
    //     onSuccess(transaction){
    //       let message = `${transaction.reference}`
    //       navigate('/ThankYou', {state: 
    //         {images,
    //           TotalAccFee,
    //           rating,
    //           numberOfGuest,
    //           title, 
    //           dateDifference,
    //           formattedStartDate,
    //           formattedEndDate,
    //           numberOfDays,
    //           price,
    //           TotalFee,
    //         message,
    //         firstName,
    //         lastName,
    //         email,
    //         phone
    //       }})
    //     },
    //     onCancel(){
    //       navigate(-1)
    //     }
    //   })
    // }
  const payWithPayPal = (e) => {
    e.preventDefault()

    if(!email || !phone || !firstName || !lastName){
      setError('All fields are required')
    }else{
      navigate('/PayPal', {state: 
        {images,
          TotalAccFee,
          rating,
          numberOfGuest,
          title, 
          dateDifference,
          formattedStartDate,
          formattedEndDate,
          numberOfDays,
          price,
          TotalFee,
          firstName,
          lastName,
          email,
          phone,
          id
      }})
      setCheckout(true)
    }

    }
    
  return (
      <div className='flex items-center justify-center w-full font-Poppins py-[3rem] xl:px-0 lg:px-0 px-[1rem]'>
        <div className='flex justify-center py-4 flex-col  xl:w-[70%] lg:w-[70%]'>
          <h1 className='xl:text-2xl lg:text-2xl text-xl flex items-center gap-3 text-green-950'><IoIosArrowBack className='text-TEXT cursor-pointer hover:text-BG' onClick={() => navigate(-1)}/>Make Payment</h1>
          <div className='border-[2px] p-6 rounded-[5px] my-6'>
            <div>
              <div className='flex items-center gap-3 mb-5'>
                <img src={images[0]} alt="" className='w-[200px] rounded-sm'/>
                <h1>{title}</h1>
              </div>
              <h1>Guests: {numberOfGuest}</h1>
              <div className='flex items-center justify-between flex-wrap'>
                <h1 className='flex items-center gap-3'>Dates: <p className='text-TEXT underline'>{formattedStartDate}</p> - <p className='text-TEXT underline'>{formattedEndDate}</p></h1>
                <h1 className='flex items-center gap-2'>Total: <p className='text-[19px] text-TEXT'>${TotalAccFee}</p></h1>
              </div>
            </div>
            <form action="" className='flex flex-wrap gap-3 py-6'>
              <div className='flex flex-col xl:w-[45%] lg:w-[45%] my-2'>
                <label htmlFor="" className='mb-1'>First name:</label>
                <input type="text" placeholder='Enter First name' value={firstName} onChange={(e) => setFirstName(e.target.value)} required className={`p-[10px] border-[2px] ${error && 'border-[#FF0000]'} focus:outline-none focus:border-TEXT`}/>
              </div>
              <div className='flex flex-col xl:w-[45%] lg:w-[45%] my-2'>
                <label htmlFor="" className='mb-1'>Last name:</label>
                <input type="text" placeholder='Enter Last name' value={lastName} onChange={(e) => setLastName(e.target.value)} required className={`p-[10px] border-[2px] ${error && 'border-[#FF0000]'} focus:outline-none focus:border-TEXT`}/>
              </div>
              <div className='flex flex-col xl:w-[45%] lg:w-[45%] my-2'>
                <label htmlFor="" className='mb-1'>Phone:</label>
                <input type="phone" placeholder='Phone number' value={phone} onChange={(e) => setPhone(e.target.value)} required className={`p-[10px] border-[2px] ${error && 'border-[#FF0000]'} focus:outline-none focus:border-TEXT`}/>
              </div>
              <div className='flex flex-col xl:w-[45%] lg:w-[45%] my-2'>
                <label htmlFor="" className='mb-1'>Email address:</label>
                <input type="text" placeholder='Email Address' value={email} onChange={(e) => setEmail(e.target.value)} required className={`p-[10px] border-[2px] ${error && 'border-[#FF0000]'} focus:outline-none focus:border-TEXT`}/>
              </div>
              {error && (<p className='text-[#FF0000]'>{error}</p>)}
              {/* <input type="text" placeholder={TotalAccFee} value={amount} onChange={(e) => setAmount(e.target.value)}/>             */}
            </form>
          </div>
          <motion.button whileTap={{scale:1.1}} className='py-[12px] text-white bg-green-950 uppercase hover:bg-TEXT border-0 flex items-center justify-center gap-3 xl:w-[10rem] lg:w-[10rem] text-[17px]' onClick={payWithPayPal}>pay<span className=''>${TotalAccFee}</span></motion.button>
        </div>
      </div>
  )
  }


export default Payment