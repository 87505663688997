import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useUserAuth } from '../context/userAuthContext'


const RequireAuth = () => {

    const {user} = useUserAuth()
    const location = useLocation()

  return (
    user? <Outlet /> : <Navigate to='/SignIn' state={{from: location}} replace />
  )
}

export default RequireAuth