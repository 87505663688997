import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { Link, NavLink, Outlet } from 'react-router-dom'
import { useUserAuth } from '../context/userAuthContext'
import SignIn from './SignIn'


const Dashboard = () => {
    const [adminNav, setAdminNav] = useState('#admin')
    const {user} = useUserAuth()
  

  return (
    <>
    {user?
    <motion.div initial ={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} transition={{duration:1}}>
        <div className='py-[7px] bg-green-800'>
          <ul className='flex items-center justify-center xl:gap-10 lg:gap-10 gap-4  p-[5px] font-Poppins text-white whitespace-nowrap overflow-auto text-sm'>
                <Link to='/dashboard' className={adminNav === '#admin' ? 'admin' : 'hover:text-TEXT p-[5px]'} onClick={() => setAdminNav('#admin')}>Dashboard</Link>
                <Link to='/dashboard/orders' className={adminNav === '#orders' ? 'admin' : 'hover:text-TEXT p-[5px]'} onClick={() => setAdminNav('#orders')}>Bookings</Link>
                <Link to='/dashboard/apartmentLists' className={adminNav === '#lists' ? 'admin' : 'hover:text-TEXT p-[5px]'} onClick={() => setAdminNav('#lists')}>Apartment Lists</Link>
                <Link to='/dashboard/user' className={adminNav === '#user' ? 'admin' : 'hover:text-TEXT p-[5px]'} onClick={() => setAdminNav('#user')}>User</Link>
          </ul>
        </div>
        <div className='px-[1rem] xl:px-[3rem] lg:px-[3rem]'>
            <Outlet/>
        </div>
    </motion.div> :
      <SignIn/>
  }
  </>
  )
}

export default Dashboard