import React, { useRef, useState } from 'react'
import main_img from '../images/re_bg.jpg'
import main_img1 from '../images/re_bg1.jpg'
import { motion } from 'framer-motion'
import { FiPhoneCall } from 'react-icons/fi'
import emailjs from 'emailjs-com'
import { ToastContainer, toast } from 'react-toastify'


const Real_Estate = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [location, setLocation] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')

  

const form = useRef();
const sendEmail = (e) => {
  e.preventDefault();

  if(!name || !email || !location || !message || !subject){
    setError('All fields are required')
    toast.error('All fields are required')
}else{
  emailjs.sendForm('service_w8b6iot', 'template_djne3ii', form.current, 'lPlwBbEcx2sMs63c3')
    .then((result) => {
        console.log(result.text)
        toast.success('Message sent successfully')
        setName('')
        setEmail('')
        setLocation('')
        setMessage('')
        setSubject('')
    }, (error) => {
        console.log(error.text);
    });
    
}};

  return (
    <motion.div initial ={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} transition={{duration:2}} className='py-[3rem] xl:py-0 lg:py-0'>
      <div className='flex items-center justify-center xl:h-[80vh] h-auto font-Poppins xl:flex-nowrap flex-wrap  gap-4 px-[1rem] xl:px-[3rem] lg:px-[3rem]'>
        <div className='xl:basis-1/2'>
          <h4 className='text-[#FF0000] text-[18px]'>Mecs Daedal Canada Inc.</h4>
          <h1 className='text-5xl capitalize leading-[4rem] text-TEXT py-4'>the real estate <br />contains <br />your ideal home</h1>
          <p>We are more than just a real estate and planning company - we are planners of your dreams, transforming spaces into thriving communities. With a wealth of experience and a commitment to innovation, we specialize in real estate development and urban planning, creating environments that inspire and endure.</p>
        </div>
        <div className='xl:basis-1/2'>
          <img src={main_img1} alt="" className='xl:h-[80vh] lg:h-[80vh] object-cover object-center'/>
        </div>
      </div>
      <div className='flex bg-gray-100 py-14 px-[1rem] xl:px-[3rem] lg:px-[3rem] gap-10 xl:flex-nowrap flex-wrap'>
        <div className='xl:basis-1/2'>
          <img src={main_img} alt="" className='xl:h-[55rem] xl:rounded-tr-[7rem] lg:rounded-tr-[7rem] rounded-tr-[4rem] xl:block lg:block md:block hidden object-cover object-center'/>
        </div>
        <div className='xl:basis-1/2 font-Poppins'>
          <h3 className='uppercase font-Heading text-TEXT pb-1 text-[15px]'>our great services</h3>
          <h1 className='xl:text-4xl text-[1.7rem] capitalize text-TEXT pb-2'>Why choose us</h1>
          <img src={main_img} alt="" className='rounded-[10px] object-cover object-center mb-4 xl:hidden lg:hidden md:hidden block'/>
          <div className='flex justify-between gap-3 basis-1/1 my-2 xl:flex-nowrap md:flex-nowrap lg:flex-nowrap flex-wrap'>
            <div className='border-2 border-gray-700 rounded-sm p-4 xl:basis-1/2 lg:basis-1/2 md:basis-1/2 basis-1/1 flex items-center flex-col' id='re_bg'>
              <h1 className='text-[18px] text-TEXT pb-1'>Our Vision</h1>
              <p>Envision a future where communities flourish, where living spaces are meticulously designed to enhance well-being, and where every project embodies sustainable and forward-thinking practices. At Mecs Daedal Canada Inc, this vision fuels our passion for excellence.</p>
            </div>
            <div className='xl:basis-1/2 lg:basis-1/2 md:basis-1/2 basis-1/1 border-2 border-gray-700 rounded-sm p-4 flex items-center flex-col' id='re_bg'>
              <h1 className='text-[18px] text-TEXT pb-1'>Real Estate Development</h1>
              <p>Our real estate development portfolio showcases a diverse range of projects, from residential neighborhoods to commercial spaces and mixed-use developments. We take pride in creating spaces that not only meet the needs of today but anticipate the demands of tomorrow. Explore our projects and discover the Mecs Daedal difference.</p>
            </div>
          </div>
          <div className='flex justify-between gap-3 basis-1/1 my-2 xl:flex-nowrap md:flex-nowrap lg:flex-nowrap flex-wrap'>
            <div className='border-2 border-gray-700 rounded-sm p-4 xl:basis-1/2 lg:basis-1/2 md:basis-1/2 basis-1/1 flex items-center flex-col' id='re_bg'>
              <h1 className='text-[18px] text-TEXT pb-1'>Urban Planing Expertise</h1>
              <p>As stewards of thoughtful urban development, our planning team employs cutting-edge strategies to shape communities that are both functional and aesthetically pleasing. We collaborate with municipalities, architects, and stakeholders to ensure our projects align with sustainable practices and contribute positively to the urban fabric.</p>
            </div>
            <div className='border-2 border-gray-700 rounded-sm p-4 xl:basis-1/2 lg:basis-1/2 md:basis-1/2 basis-1/1 flex items-center flex-col' id='re_bg'>
              <h1 className='text-[18px] text-TEXT pb-1'>Our Commitment to Sustainability</h1>
              <p>Mecs Daedal Canada Inc is dedicated to fostering environmentally conscious developments. Our commitment to sustainability is woven into the fabric of our projects, incorporating green spaces, energy-efficient designs, and eco-friendly practices. We believe in creating spaces that harmonize with nature and stand the test of time.</p>
            </div>
          </div>
          <div className='flex justify-between gap-3 basis-1/1 my-2'>
            <div className='border-2 border-gray-700 rounded-sm p-4 flex items-center flex-col basis-1/1' id='re_bg'>
              <h1 className='text-[18px] text-TEXT pb-1'>Innovative Solutions for Every Project</h1>
              <p>Whether you are envisioning a residential enclave, a commercial hub, or a mixed-use development, Mecs Daedal Canada Inc is your partner in realizing your vision. Our team of seasoned professionals brings creativity, expertise, and a commitment to excellence to every project, ensuring unparalleled results.</p>
            </div>
            
          </div>
        </div>

      </div>
      <div className='flex xl:basis-1/2 lg:basis-1/2 basis-1/1 py-16 px-[1rem] xl:px-[3rem] lg:px-[3rem] font-Poppins xl:flex-nowrap lg:flex-nowrap flex-wrap gap-16'>
        <div className='xl:basis-1/2 lg:basis-1/2'>
          <h1 className='xl:text-3xl text-xl capitalize text-TEXT pb-2'>Explore Opportunities with Us</h1>
          <p className=''>Are you looking to invest, partner, or collaborate on a transformative real estate project? <br />Mecs Daedal Canada Inc welcomes opportunities for growth and collaboration. <br />Connect with us to explore how we can bring your vision to life and contribute to the evolution of vibrant communities.</p>
          <div className='py-8'>
            <h3 className='font-Poppins text-TEXT uppercase xl:text-[1.2rem] lg:text-[1.2rem] text-[16px] pb-3'>Contact</h3>
            <h1 className='capitalize xl:text-[2rem] text-xl xl:leading-[2.5rem] lg:leading-[3.5rem]'>need any assistance? <br />we are always here </h1>
            <div className='pt-8 flex items-center gap-4'>
            <motion.button whileTap={{scale:1.1}} className='px-[10px] py-4 bg-TEXT border-0 text-white capitalize hover:bg-BG rounded-[3px]'><a href="mailto:admin@mecsdaedal.ca">Contact Us</a></motion.button>
            <motion.button whileTap={{scale:1.1}} className='px-[10px] flex items-center gap-3 py-3 border-2 text-TEXT  hover:text-white hover:bg-BG rounded-[3px]'><FiPhoneCall className=' text-[1.2rem]'/><a href="tel:+17808071142" className=' text-[18px]'>+1(780)807-1142</a></motion.button>
            </div>
          </div>
        </div>
        <div className='xl:basis-1/2 lg:basis-1/2 p-6 border-2 border-TEXT rounded-[5px] w-full'>
            <h1 className='text-TEXT capitalize py-3'>send your questions.</h1>
            <form action="" ref={form} className='flex flex-col'>
              <div className='flex items-center justify-between w-full pb-3 xl:flex-nowrap lg:flex-nowrap flex-wrap gap-3'>
                <input className={`${error ? 'border-[#FF0000] transition ease-in-out 100ms' : ''} h-[45px] px-2 border-2 rounded-[3px] w-full`} type="text"  name='name' value={name} id='name' onChange={(e) => setName(e.target.value)} placeholder='Your Name'/>
                <input className={`${error ? 'border-[#FF0000] transition ease-in-out 100ms' : ''} h-[45px] px-2 border-2 rounded-[3px] w-full`} type="email" name='email' value={email} id='email' onChange={(e) => setEmail(e.target.value)} placeholder='E-mail Address' />
              </div>
              <div className='flex items-center justify-between w-full pb-3 xl:flex-nowrap lg:flex-nowrap flex-wrap gap-3'>
                <input className={`${error ? 'border-[#FF0000] transition ease-in-out 100ms' : ''} h-[45px] px-2 border-2 rounded-[3px] w-full`} type="text" placeholder='Subject' name='subject' id='subject' value={subject} onChange={(e) => setSubject(e.target.value)}/>
                <input className={`${error ? 'border-[#FF0000] transition ease-in-out 100ms' : ''} h-[45px] px-2 border-2 rounded-[3px] w-full`} type="text" placeholder='Location' name='location' id='location' value={location} onChange={(e) => setLocation(e.target.value)}/>
              </div>
              <textarea className={` ${error ? 'border-[#FF0000] transition ease-in-out 100ms' : ''} px-2 border-2 rounded-[3px] h-[200px] resize-none`} id="message" placeholder='Message' name='message' cols="30" rows="10" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
              {error && <p className='text-[#FF0000] py-2'>{error}</p>}
              <motion.button whileTap={{scale:1.1}} className='py-4 mt-5 rounded-[3px] bg-TEXT hover:bg-BG border-0 text-white' onClick={sendEmail}>Submit</motion.button>
            </form>
        </div>
      </div>
    </motion.div>
  )
}

export default Real_Estate