import React, { useRef, useState } from 'react'
import { MdLocalPhone, MdEmail } from "react-icons/md";
import { ImLocation2 } from "react-icons/im";
import { motion } from 'framer-motion';
import { ToastContainer, toast } from 'react-toastify';
import emailjs from 'emailjs-com'


const Contact = () => {
    const [error, setError] = useState(null)
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [name, setName] = useState('')
    const [message, setMessage] = useState('')
    const [phone, setPhone] = useState('')
    
    const form = useRef();
    const handleSubmit = (e) => {
        e.preventDefault()

        if(!email || !subject || !name || !phone || !message){
            setError('All fields are required')
            toast.error('All fields are required')
        } else { 
            emailjs.sendForm('service_w8b6iot', 'template_djne3ii', form.current, 'lPlwBbEcx2sMs63c3')
            .then((result) => {
            console.log(result.text)
            toast.success('Message Sent Successfully')
            setEmail('')
            setName('')
            setMessage('')
            setPhone('')
            setSubject('')
        },
        (error) => {
            console.log(error.text);
        });
    }};

  return (
    <motion.div initial ={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} transition={{duration:2}} className=''>
        <div className='flex items-center justify-center font-Poppins h-[50vh] flex-col' id='info_bg'>
            <h1 className='text-3xl capitalize pb-4 text-white'>Contact informations</h1>
            <p className='xl:w-[50%] lg:w-[50%] text-center text-clip px-[1rem] xl:px-0 lg:px-0 text-white'>Mecs Daedal Canada Inc welcomes opportunities for growth and collaboration. Connect with us to explore how we can bring your vision to life and contribute to the evolution of vibrant communities.</p>
        </div>
        <div className='flex items-start justify-center gap-[3rem] w-full font-Poppins xl:flex-nowrap lg:flex-nowrap flex-wrap bg-gray-100 py-[4rem]'>
            <div className='flex items-center gap-5 xl:w-[20%] lg:w-[20%] flex-col'>
                <div className='flex items-center gap-5 p-5 w-full' id='contact_bg'>
                    <div className='p-3 rounded-full bg-TEXT'>
                        <MdLocalPhone className='text-[18px] text-white'/>
                    </div>
                    <div>
                        <h1 className='text-BG'>Phone Number</h1>
                        <p>+1(780)807-1142</p>
                    </div>
                </div>
                <div className='flex items-center gap-5 p-5 w-full' id='contact_bg'>
                    <div className='p-3 rounded-full bg-TEXT'>
                        <MdEmail className='text-[18px] text-white'/>
                    </div>
                    <div>
                        <h1 className='text-BG'>Email address</h1>
                        <p className=''>admin@mecsdaedal.ca</p>
                    </div>
                </div>

                <div className='flex items-center gap-5 p-5 w-full' id='contact_bg'>
                    <div className='p-3 rounded-full bg-TEXT'>
                        <ImLocation2 className='text-[18px] text-white'/>
                    </div>
                    <div>
                        <h1 className='text-BG'>Location</h1>
                        <p>1344 157 street SW, Edmonton, T6W4K7</p>
                    </div>
                </div>
                
            </div>
            <div className='xl:w-[50%] lg:w-[50%] p-8' id='contact_bg'>
                <h1 className='text-xl pb-4 text-TEXT'>Send Message</h1>
                <p>Connect with us to explore how we can bring your vision to life and contribute to the evolution of vibrant communities.</p>
                <form action="" ref={form} className='my-10'>
                    <div className='pb-3 flex items-center justify-between flex-wrap w-full'>
                        <input type="text" name='subject' id='name' value={name} placeholder='Your name' className={`xl:mb-0 lg:mb-0 mb-2 p-2 border-2 focus:outline-none focus:border-TEXT rounded-[4px] xl:w-[49%] lg:w-[49%] w-full ${error ? 'border-[#FF0000] transition ease-in-out 100ms' : ''}`} onChange={(e) => setName(e.target.value)}/>
                        <input type="text" name='email' id='email' value={email} placeholder='Email address' className={`p-2 border-2 focus:outline-none focus:border-TEXT rounded-[4px] xl:w-[49%] lg:w-[49%] w-full ${error ? 'border-[#FF0000] transition ease-in-out 100ms' : ''}`} onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                    <div className='pb-3 flex items-center justify-between flex-wrap w-full'>
                        <input type="text" name='phone' id='phone' value={phone} placeholder='Phone number' className={`${error? 'border-[#FF0000] transition ease-in-out 100ms' : ''} xl:mb-0 lg:mb-0 mb-2 p-2 border-2 focus:outline-none focus:border-TEXT rounded-[4px] xl:w-[49%] lg:w-[49%] w-full`} onChange={(e) => setPhone(e.target.value)}/>
                        <input type="text" name='name' id='subject' value={subject} placeholder='Subject' className={`${error? 'border-[#FF0000] transition ease-in-out 100ms' : ''} p-2 border-2 focus:outline-none focus:border-TEXT rounded-[4px] xl:w-[49%] lg:w-[49%] w-full`} onChange={(e) => setSubject(e.target.value)}/>
                    </div>
                    <textarea name="message" id="message" cols="30" rows="10" placeholder='Message' value={message} className={`${error? 'border-[#FF0000] transition ease-in-out 100ms' : ''} p-2 border-2 focus:outline-none focus:border-TEXT rounded-[4px] resize-none h-[100px] w-full`} onChange={(e) => setMessage(e.target.value)}></textarea>
                    {error && <p className='text-[#FF0000] font-Poppins pt-2'>{error}</p>}
                </form>
                <motion.button whileTap={{scale:1.1}} className='bg-TEXT hover:bg-black py-3 text-white border-0 rounded-[4px]' onClick={handleSubmit}>Send Message</motion.button>
            </div>
        </div>
        <ToastContainer/>
    </motion.div>
  )
}

export default Contact