import React from 'react'
import RoomsCard from './RoomsCard'
import Search from './Search'
import { motion } from 'framer-motion'

const Apartments = () => {
  return (
    <motion.div initial ={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} transition={{duration:1}} className='flex items-center justify-center flex-col'>
    <h1 className='xl:text-[3rem] lg:text-[3rem] text-[2rem] font-Poppins flex items-center justify-center text-center w-full xl:h-[50vh] lg:h-[50vh] sm:h-[40vh] h-[30vh] text-white' id='room_bg'>our featured rooms</h1>
    <Search/>
    <RoomsCard/>
    </motion.div>
  )
}

export default Apartments