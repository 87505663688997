import React, { useState } from 'react'
import {IoMdStar} from 'react-icons/io'
import {MdLocationOn} from 'react-icons/md'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai'
import { ToastContainer, toast } from 'react-toastify'
import { LuDot } from "react-icons/lu";
import { IoLocation } from "react-icons/io5";
import { useUserAuth } from '../context/userAuthContext'



const RoomDetails = ({ title, location, price, images, id,reviews, averageRating, city}) => {
    const {numberOfGuest} = useUserAuth()
    

    const [isFav, setIsFav] = useState(false)
    const selectFav = () => {
            setIsFav(!isFav)
            if(!isFav){
              toast.success('Added to favourite')
            }else{
              toast.error('Removed from favourite')
            }
          }
  return (
    <div className='w-full' key={id}>
        <div className='w-[100%]'>
                <Link to={`/apartments/${id}`} key={id}>
                    <div className='w-[100%] flex justify-between xl:p-6 lg:p-6 p-3 rounded-[5px] flex-col xl:flex-row lg:flex-row md:flex-col hover:bg-gray-200 xl:h-[320px] lg:h-[320px]' id='bg-effect'>
                        <img src={images[0]} alt={title}  className='rounded-[10px] xl:w-[450px] lg:w-[420px] md:w-[420px] xl:h-[270px] lg:h-[270px] h-[230px] md:h-[270px] object-fill object-center'/>
                        <div className='xl:w-[50%] lg:w-[50%] mt-[1.5rem] xl:mt-0 lg:mt-0 md:mt-[1.5rem]'>
                            <div className='flex items-center justify-between'>
                                <h1 className='text-[20px] text-TEXT py-2 capitalize'>{title}</h1>
                                <p onClick={selectFav} className='cursor-pointer hover:scale-150'>{isFav ? <AiFillHeart className='text-[19px] text-TEXT'/> : <AiOutlineHeart className='text-[19px]'/>}</p>
                            </div>
                            <div className='flex pb-3 flex-col'>
                                <h1 className=''></h1>
                                <h1 className='pb-2 text-gray-800 flex items-center gap-1 capitalize'><IoLocation/>{city}, {location}</h1>
                                <p className='text-gray-800 flex items-center text-center flex-wrap'><LuDot className='text-TEXT'/> 3 bedroom <LuDot className='text-TEXT'/> bathrooms <LuDot className='text-TEXT'/> beds <LuDot className='text-TEXT'/></p>
                            </div>
                            <div className='flex items-center justify-between'>
                                <p>Reviews ({reviews.length})</p>
                                <h1 className='xl:text-[23px] lg:text-[23px] text-[20px] flex items-center gap-1'><span className='text-[15px]'></span> ${price} <span className='text-[17px]'>/ night</span></h1>
                            </div>
                            <div className='flex items-center justify-between xl:py-5 lg:py-5 py-1'>
                                <p className='flex items-center gap-1'><IoMdStar className='text-TEXT text-[17px]'/>{averageRating}</p>
                                {/* <p className='xl:text-[23px] lg:text-[23px] text-[20px] flex items-center gap-1'><span className='text-[15px]'></span>${price} <span className='text-[15px]'>total</span></p> */}
                            </div>
                        </div>
                    </div>

                </Link>
        </div>
        <ToastContainer/>
    </div>
    
  )
}

export default RoomDetails