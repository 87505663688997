import React, { useState } from 'react'
import {BiSearch} from 'react-icons/bi'
import {FaUsers} from 'react-icons/fa'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { useNavigate } from 'react-router-dom';
import 'animate.css';
import { useUserAuth } from '../context/userAuthContext';


const Search = ({placeholder}) => {
    const navigate = useNavigate()
    const {selectionRange, handleSelect, cancelSearch,searchInput, endDate, startDate,numberOfGuest, setNumberOfGuest, handleSearch} = useUserAuth()
   
  return (
    <>
    <div className='flex items-center justify-center gap-5 pb-8 pt-8 w-full'>
        <div className='flex items-center justify-between xl:w-[40%] lg:w-[40%] w-[90%] p-[2px] rounded-[30px] border-[2px] border-TEXT' id='details_bg'>
            <input type="text" placeholder={placeholder || 'search location  |  anywhere'} value={searchInput} onChange={handleSearch} className='p-3 focus:outline-none w-full bg-transparent indent-2 placeholder:text-center text-center placeholder:text-[16px] placeholder:font-Poppins placeholder:text-gray-800'/>
            <BiSearch className='text-[20px] text-TEXT mr-2 cursor-pointer' onClick={() => {navigate('/SearchResults', {state: {
                    searchInput,
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                    numberOfGuest,
            }})}}/>
        </div>
    </div>
    {searchInput && (
    <div className='py-[20px] animate__animated animate__slideInUp' id='date-picker'>
        <DateRangePicker ranges={[selectionRange]} minDate={new Date()} rangeColors={['#F05E16']} onChange={handleSelect} className='mx-auto flex-col flex xl:flex-row lg:flex-row md:flex-row font-Poppins'/>
        <div className='flex items-center justify-between font-Poppins'>
            <h1>Number of Guests</h1>
            <div className='flex items-center w-[5rem] gap-3'>
                <FaUsers className='text-[20px] text-TEXT'/>
                <input type="number" value={numberOfGuest} onChange={(e) => setNumberOfGuest(e.target.value)} min={1} className='w-[35px]'/>
            </div>
        </div>
        <div className='flex items-center justify-around pt-5 font-Poppins'>
            <button className='hover:bg-TEXT hover:text-white' onClick={cancelSearch}>Cancel</button>
            <button className='hover:bg-TEXT hover:text-white' onClick={() => {navigate('/SearchResults', {state: {
                    searchInput,
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                    numberOfGuest,
            }})}}>Search</button>
        </div>
    </div>)}

    </>
  )
}

export default Search