import React, { useContext } from 'react'
import {IoMdStar} from 'react-icons/io'
import {MdLocationOn} from 'react-icons/md'
import { Link } from 'react-router-dom'
import { useUserAuth } from '../context/userAuthContext'



const RoomsCard = () => {
    const {Apartments} = useUserAuth()
    const {id, images, title, location,price,reviews, averageRating, city} = Apartments
    const totalRating = reviews?.reduce((acc,averageRating) => acc + averageRating, 0)
    const avgRating = totalRating === 0 ? '' 
    : totalRating === 1 ? totalRating 
    : totalRating/reviews?.length

  return (
    <div className='flex items-center justify-center flex-col'>
        {/* <p onClick={() => navigate(-1)} className='px-[1rem] xl:px-[3rem] lg:px-[3rem] flex items-start mr-auto justify-start py-4 gap-2 font-Poppins hover:text-TEXT cursor-pointer'><BsArrowLeftCircle className='text-[20px]'/>Back</p> */}
        <div className='flex justify-center py-[3rem] flex-wrap gap-8 font-Roboto px-[1rem] xl:px-0 lg:px-0 md:px-0'>
                {Apartments.map(({id, images, title, location,price,reviews, averageRating, city}) => (
                <Link to={`/apartments/${id}`} key={id}>
                <div className='flex items-center justify-center xl:w-[330px] lg:w-[330px] flex-col hover:scale-105 transition ease-in-out rounded-[5px] p-[5px]'>
                    <img src={images[0]} alt={title} className='max-h-[250px] h-[250px] object-cover object-center rounded-lg'/>
                    <div className='flex items-center justify-between w-[100%] py-2 text-[15px]'>
                        <h1 className='capitalize'><MdLocationOn className='text-TEXT'/>{city}, {location}</h1>
                        {/* <p className='flex items-center'><IoMdStar className='text-TEXT'/>{avgRating === 0 ? null : avgRating}</p> */}
                    </div>
                    <div className='flex items-center justify-between w-[100%] text-[15px]'>
                        <h1 className=''>${price}<span className='font-normal'>/night</span></h1>
                        {totalRating === 0 ? ('Not rated') : <p>Rating: ({averageRating})</p>}
                    </div>                    
                </div>
            </Link>))}
        </div>
    </div>
    
  )
}

export default RoomsCard