import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { RiMenu4Fill, RiCloseFill } from 'react-icons/ri';
import { FaUserCircle } from 'react-icons/fa';
import { IoIosArrowDropdownCircle } from 'react-icons/io';
import { useUserAuth } from '../context/userAuthContext';
import logo from '../images/MEDS-DAEDAL.png'
import { motion } from 'framer-motion';


const Nav_Link = [
    {
        path: '/home',
        display: 'home'
    },
    {
        path: '/bookings',
        display: 'bookings'
    },
    {
        path: '/logistics',
        display: 'logistics'
    },
    {
      path: '/real_estate',
      display: 'real estate'
  },
  {
    path: '/contact',
    display: 'contact us'
},

]



const Header = () => {
  const {user, logOut} = useUserAuth()
  const USER_ADMIN = 'Sjzj7omKR2PiSnrpOSGRmHfMnIp1'
  const [mobileNav, setMobileNav] = useState(false)
  const [show, setShow] = useState(false)
  const [activeNav, setActiveNav] = useState('#main_bg')
  const [logOutMenu, setLogOutMenu] = useState(false)

  const transitionNav = () => {
    if(window.scrollY > 100) {
      setShow(true)
    }else{
      setShow(false)
    }
   }

   useEffect(() => {
    window.addEventListener('scroll', transitionNav)
    return () => window.removeEventListener('scroll',transitionNav)
   },[])

   const logUserOut = async () => {
    try {
      await logOut()
      setLogOutMenu(false)
    } catch {
      alert('error')
    }
   }
  return (
    
    <header className='font-Poppins'>
      <div className={`flex items-center justify-between px-[1rem] xl:px-[3rem] lg:px-[3rem] border-b-2 ${show && 'fixed left-0 right-0 bg-white transition transform duration-100 ease-in-out shadow-lg z-[100]'}`}>
        <div>
          <Link to='/'>
            <img src={logo} alt="mecs daedal" className='w-[100px]'/>
          </Link>
        </div>
        <div>
          <ul className='hidden items-center xl:flex lg:flex md:flex gap-5'>
            {Nav_Link.map((item, index) => (
              <li className='hover:text-TEXT' key={index}>
                <NavLink to={item.path} className={activeNav === '' ? 'active' : ''} onClick={() => setActiveNav(!activeNav)}>{item.display}</NavLink>
              </li>
            ))}
          </ul>
          
        </div>

        {user?
        <div className='hidden xl:flex lg:flex md:flex gap-4 items-center'>
          <p className='flex items-center gap-1'>{user.photoURL? <img src={user.photoURL} alt='photo' className='max-w-[30px] rounded-full'/> : <FaUserCircle className='text-[25px]'/>}<span className='text-[17px]'></span></p>
          <IoIosArrowDropdownCircle onClick={() => setLogOutMenu(setLogOutMenu => !setLogOutMenu)} className='text-TEXT cursor-pointer'/>
          {logOutMenu && 
          <motion.div initial ={{x:50}} animate={{x:0}} exit={{opacity:0}} transition={{duration:0.5}} className='absolute right-[50px] top-[70px] flex flex-col bg-gray-100 divide-y-2 rounded-[4px]'>
            {user?.uid === USER_ADMIN ?
            <Link to='/Dashboard' onClick={() => setLogOutMenu(false)}><button className='hover:bg-TEXT hover:text-white border-0 rounded-tl-[4px] rounded-tr-[4px]'>Dashboard</button></Link>
            : 
            <Link to='/Profile' onClick={() => setLogOutMenu(false)}><button className='hover:bg-TEXT hover:text-white border-0 rounded-tl-[4px] rounded-tr-[4px]'>Profile</button></Link>
            }
            <button className='hover:bg-TEXT hover:text-white border-0 rounded-br-[4px] rounded-bl-[4px]' onClick={logUserOut}>Logout</button>
          </motion.div>}
        </div> :
        <div className='hidden xl:flex lg:flex md:flex gap-4'>
          <button className='hover:text-white hover:bg-TEXT'><Link to='/signIn'>Sign in</Link></button>
          <button className='bg-BG text-white hover:bg-TEXT '><Link to='/register'>Register</Link></button>
        </div>}
        <span className='xl:hidden lg:hidden md:hidden flex items-center gap-2'>
          {user? 
          <div className='flex items-center gap-[5px]'>
            <p className='flex items-center gap-1'><img src={user.photoUrl} alt=""  className='max-w-[10px]'/><span className='w-[70px] overflow-x-scroll'></span></p>
            <IoIosArrowDropdownCircle onClick={() => setLogOutMenu(setLogOutMenu => !setLogOutMenu)} className='text-TEXT cursor-pointer'/>
            {logOutMenu && 
          <motion.div initial ={{x:50}} animate={{x:0}} exit={{opacity:0}} transition={{duration:0.5}} className='absolute right-[50px] top-[70px] flex  flex-col divide-y-2 bg-gray-100 rounded-[4px]'> 
            {user?.uid === USER_ADMIN ? <Link to='/Dashboard' onClick={() => setLogOutMenu(false)}><button className='hover:text-white hover:bg-TEXT border-0 rounded-tl-[4px] rounded-tr-[4px]'>Dashboard</button></Link> 
            :
            <Link to='/profile' onClick={() => setLogOutMenu(false)}><button className='hover:bg-TEXT hover:text-white border-0 rounded-tl-[4px] rounded-tr-[4px]'>Profile</button></Link>}
            <button className='hover:text-white hover:bg-TEXT border-0 rounded-br-[4px] rounded-bl-[4px]' onClick={logUserOut}>Logout</button>
          </motion.div>}
          </div>: 
          <Link to='/signIn'><span className='text-[15px] pr-2 text-BG hover:text-TEXT'>Sign in</span></Link>
          }
          {mobileNav?<RiCloseFill className='text-[20px] cursor-pointer' onClick={() => setMobileNav(!mobileNav)}/> :<RiMenu4Fill className='text-[20px] cursor-pointer' onClick={() => setMobileNav(!mobileNav)}/>}
        </span>
      </div>
        
      {mobileNav && 
      <motion.div animate={{y:0, scale: 1}} initial={{scale: 1, y:100}} transition={{type: 'spring', stiffness: 150}} className='flex flex-col  gap-5 py-4 px-5 text-BG fixed bg-white left-0 right-0 top-[70px] z-[99999999] h-[100vh]'>
        <div className=''>
          <ul className='flex gap-5 flex-col'>
            {Nav_Link.map((item, index) => (
              <li className='hover:text-TEXT py-[10px]'>
                <NavLink to={item.path} onClick={() => setMobileNav(false)}>{item.display}</NavLink>
              </li>
            ))}
          </ul>
        </div>
        {/* {user? <p className='flex items-center gap-1 border-2 p-2'><FaUserCircle className='text-[25px]'/><span className=''>{user.email}</span></p> : 
        ''} */}
      </motion.div>}
    </header>
    
  )
}

export default Header