import React from "react";
import Router from './routers/Router.jsx'

function App() {
  return (
      <Router basename='/mecsdaedal'/>
  );
}

export default App;
