import React, { useEffect, useRef, useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { useLocation, useNavigate } from 'react-router-dom'
import { useUserAuth } from '../../context/userAuthContext'
import { addDoc, collection, doc, getDocs } from 'firebase/firestore';



const PayPal = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const paypal = useRef()
    const {images,TotalAccFee,rating,numberOfGuest, title, dateDifference, formattedStartDate, formattedEndDate, numberOfDays, firstName, lastName, phone, email,id} = location.state
    const {setStatus, toggleStatus, status, orders, setBookings} = useUserAuth()
    const [state, setState] = useState('')

    const handleCreate = async () => {
        await addDoc(orders, {name: firstName + '=' + lastName, start: formattedStartDate, end: formattedEndDate, total: TotalAccFee, days:numberOfDays, email:email, phone:phone, apartment:title })
    }


    useEffect(() => {
        window.paypal.Buttons({
            createOrder: (data, actions, err) => {
                return actions.order.create({
                    intent: 'CAPTURE',
                    purchase_units: [
                        {
                            description: title,
                        amount:{
                            currency_code: 'CAD',
                            value: TotalAccFee,
                        }
                    }
                    ]
                })
            },
            onApprove: async (data, actions) => {
                const order = await actions.order.capture()

                await fetch('api/email', {
                    method: 'POST',
                    body: JSON.stringify({
                        firstName,
                        lastName,
                        email,
                        phone,
                        order,
                        numberOfDays,
                        numberOfGuest,
                        formattedStartDate,
                        formattedEndDate
                    })
                })
                setState('ready')
                toggleStatus(id)
                handleCreate()
                navigate('/ThankYou', {state: 
                            {images,
                              TotalAccFee,
                              rating,
                              numberOfGuest,
                              title, 
                              dateDifference,
                              formattedStartDate,
                              formattedEndDate,
                              numberOfDays,
                            firstName,
                            lastName,
                            email,
                            phone,
                            order,
                            id
                          }})
                          
            },
            onError: (err) => {
                console.log(err);
                navigate(-1)
            }
        }).render(paypal.current)
    },[])

  return (
    <div className='flex items-center justify-center py-[2rem] flex-col px-[1rem] xl:px-[3rem] lg:px-[3rem] h-[50vh]' id='receipt_bg'>
        {/* <h1 className='xl:text-2xl lg:text-2xl text-xl flex gap-3 text-green-950 items-start'><IoIosArrowBack className='text-TEXT cursor-pointer hover:text-BG' onClick={() => navigate(-1)}/></h1> */}
        <div ref={paypal} className='flex items-center justify-center py-5'></div>
    </div>
   
  )
}

export default PayPal