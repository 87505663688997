import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { useUserAuth } from '../context/userAuthContext'
import { ToastContainer, toast } from 'react-toastify'
import { LuDot } from "react-icons/lu";
import { motion } from 'framer-motion';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'animate.css';
import supabase from '../config/supabaseClient'
import { BsEmojiHeartEyesFill, BsEmojiAngryFill, BsFillEmojiExpressionlessFill } from "react-icons/bs";
import { IoMdStar } from 'react-icons/io';



const ApartmentsDetails = ({status}) => {
  const {user, result, dateDifference,selectionRange,cancelSearch, handleSelect,searchInput, formattedStartDate, formattedEndDate,numberOfGuest, startDate,endDate, handleSearch, setStatus, handleStatus, Apartments} = useUserAuth()

  const [tab, setTab] = useState('description')
  const [rating, setRating] = useState(null)
  const reviewUser = useRef('')
  const reviewMsg = useRef('')
  const navigate = useNavigate()
  const {id} = useParams()
  const [numberOfDays, setNumberOfDays] = useState(1)
  const [reviews, setReviews] = useState([])
  const [fetchError, setFetchError] = useState(null)
  const [name, setName] = useState('')
  const [message, setMessage] = useState('')
  const [formError, setFormError] = useState('')
  const apartments = Apartments.find((Apartment) => Apartment.id == id)
  const {title, price, images, category, city, averageRating, location} = apartments
  
  useEffect(() => {
    const fetchReviews = async () => {
      const {data, error} = await supabase
      .from('reviews')
      .select()
      if(error){
        setFetchError('Could not fetch reviews')
        console.log(error);
      }
      if (data){
        setReviews(data)
        setFetchError(null)
      }
    }
    fetchReviews()
    
  }, [])

  const submitHandler = (e) => {
    e.preventDefault()
    navigate('/Confirmation', {state: {
      images,
      TotalAccFee,
      rating,
      numberOfGuest,
      title, 
      dateDifference,
      formattedStartDate,
      formattedEndDate,
      numberOfDays,
      price,
      TotalFee,
      status:status,
      id: id,

    }})
  }
  
  const submitReview = async (e) => {
    e.preventDefault()

   if(!name || !message || !rating){
    setFormError('Please fill all fields')
    toast.error('Please fill all fields')
    return
   }

   const {data, error} = await supabase
      .from('reviews')
      .insert([{name, message, rating}])
      toast.success('Review Submitted')
      setName('')
      setMessage('')
      if(error){
        setFetchError('Could not fetch reviews')
        console.log(error);
      }
      if (data){
        setFormError(null)
        console.log(data);
      }
  
  }
  const newDate = (new Date(startDate))
  const oldDate = (new Date(endDate))
  const difference = oldDate - newDate
  const dateDiff = Math.floor(difference / (1000 * 60 * 60 * 24))

 
  const ServiceFee = 0
  const cleaningFee = 85
  const TotalFee = (dateDifference || numberOfDays) * price
  const TotalAccFee = TotalFee + ServiceFee + cleaningFee


  return (
    <motion.div initial ={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} transition={{duration:1}} className='px-[1rem] xl:px-[3rem] lg:px-[3rem] flex items-start justify-center flex-col py-7'>
      {/* <p onClick={() => navigate(-1)} className='flex items-center gap-2 font-Poppins'><BsArrowLeftCircle className='text-[20px] hover:text-TEXT cursor-pointer'/>Back</p> */}
      <h1 className='xl:text-[23px] lg:text-[23px] text-[25px] font-Heading font-medium capitalize'>{title}</h1>
      <div className='flex pt-1 pb-8 w-full mt-4 mb-8 flex-wrap xl:flex-nowrap lg:flex-nowrap gap-10 xl:justify-center lg:justify-center justify-start'>
        <div className='xl:w-[50%] lg:w-[50%] w-full max-h-[30rem] xl:h-[30rem] lg:h-[30rem] md:h-[20rem]  h-[18rem]'>
        <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={images[0]} alt={title} className='w-full object-fill object-center rounded-[5px]'/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={images[1]} alt={title} className='w-full object-fill object-center rounded-[5px]'/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={images[2]} alt={title} className='w-full object-fill object-center rounded-[5px]'/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={images[3]} alt={title} className='w-full object-fill object-center rounded-[5px]'/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={images[4]} alt={title} className='w-full object-fill object-center rounded-[5px]'/>
        </SwiperSlide>
        {images[5] ? 
        <SwiperSlide>
          <img src={images[5]} alt={title} className='w-full object-fill object-center rounded-[5px]'/>
        </SwiperSlide> : ''}
        
      </Swiper>
          
        </div>

        
        <div className='flex justify-between flex-col xl:w-[50%] lg:w-[50%] w-full font-Roboto'>
          <div className='flex justify-center flex-col  w-full'>
            <h1 className='text-TEXT font-Poppins text-[20px] capitalize'>{title}</h1>
            <div className='flex xl:items-center lg:items-center flex-col xl:flex-row lg:flex-row gap-2 py-2 text-[17px]'>
              <h1 className='capitalize'>{city},</h1>
              <h1 className='capitalize'>{location}</h1>
            </div>
            <h1 className='text-gray-800 flex items-center text-center pb-3'><LuDot className='text-TEXT text-2xl'/> 3 bedroom <LuDot className='text-TEXT text-2xl'/> {} bathrooms <LuDot className='text-TEXT text-2xl'/> {} beds</h1>
            <div className='flex items-center gap-4 text-[15px]'>
              <h1 className='flex items-center gap-1'><IoMdStar className='text-[17px]'/></h1>
              <h1>Ratings: ({averageRating})</h1>
            </div>
        
            <div className='p-5 my-6 xl:w-[70%] lg:w-[70%] font-Roboto ' id='price__bg'>
              <h1 className='text-[23px] text-BG font-semibold'>${price} <span className='text-black text-[14px] font-normal'>per night</span></h1>
              <hr />
              <div className='border-b-2 leading-[58px] tracking-wide'>
                <div className='flex items-center justify-between'>
                  <h1>{price} x {dateDifference || numberOfDays} nights</h1>
                  <h1 className='text-[17px]'>${TotalFee}</h1>
                </div>
                <div className='flex items-center justify-between'>
                     <h1>Cleaning fee:</h1>
                     <h1 className='text-[17px]'>${cleaningFee}</h1>
                </div>
                <div className='flex items-center justify-between'>
                     <h1>Service fee:</h1>
                     <h1 className='text-[17px]'>${ServiceFee}</h1>
                </div>
                <div className='flex items-center justify-between'>
                     <h1>Number of days:</h1>
                     <input type="number" min={1} value={dateDifference || numberOfDays } placeholder={dateDifference || numberOfDays} onChange={handleSearch} className='max-w-[40px] focus:outline-none'/>
                </div>
                <h1>Booking Date</h1>
                <div className='flex items-center flex-col mb-2 bg-gray-100 px-1 rounded-[7px]'>
                     <div className='flex items-center justify-between w-full h-[45px]'>
                      <h1 className='text-gray-600'>From</h1>
                      <p className='text-gray-600'>{formattedStartDate}</p>
                     </div>
                     <div className='flex items-center justify-between w-full h-45px]'>
                      <h1 className='text-gray-600'>To</h1>
                      <p className='text-gray-600'>{formattedEndDate}</p>
                     </div>
                </div>
                
              </div>
              <div className='flex items-center justify-between py-4'>
                  <h1 className='text-[20px]'>Total</h1>
                  <h1 className='text-[20px]'>${TotalAccFee}</h1>
              </div>
              <div className='flex items-center justify-between gap-3 mt-8 xl:mt-0 lg:mt-0'>
                {/* <button className='xl:py-4 lg:py-4 md:py-4 px-2 rounded-[5px] border-[1px] border-TEXT' onClick={selectFav}>{isFav ? <AiFillHeart className= 'text-[20px] text-TEXT'/> : <AiOutlineHeart className='text-[20px]'/>}</button> */}
                <motion.button whileTap={{scale:1.1}} className={`py-4 rounded-[5px] uppercase bg-TEXT text-white border-0 hover:bg-BG items-start w-full `} onClick={submitHandler}>proceed</motion.button>
              </div>
            </div>
            
          
          </div> 
          {searchInput && <div className='font-Poppins animate__animated animate__slideInUp absolute top-[33rem] z-[999999]'>
            <DateRangePicker ranges={[selectionRange]} minDate={new Date()} rangeColors={['#F05E16']} onChange={handleSelect} className='mx-auto flex-col flex xl:flex-row lg:flex-row md:flex-row font-Poppins'/>
            <div className='flex items-center justify-between'>
            <motion.button whileTap={{scale:1.0}} onClick={cancelSearch} className='bg-BG hover:bg-TEXT text-white rounded-[5px] border-0'>Clear date</motion.button>
            <motion.button whileTap={{scale:1.0}} onClick={cancelSearch} className='bg-BG hover:bg-TEXT text-white rounded-[5px] border-0'>Select date</motion.button>
            </div>
          </div> }
        </div>
        
       
      </div>
      <div className='flex gap-3 pt-4'>
        <h4 className={`font-Poppins text-[14px] cursor-pointer px-2 ${tab === 'description' ? 'isActive' : ''}`} onClick={() => setTab('description')}>Description</h4>
        <h4 className={`font-Poppins text-[14px] cursor-pointer px-2 ${tab === 'reviews' ? 'isActive' : ''}`} onClick={() => setTab('reviews')}>Reviews: ({reviews.length})</h4>
      </div>
      {tab === 'description' ? 
      <div className='description font-Poppins py-4' >
        <h1 className='text-[14px]'></h1>
      </div> : 
      <div className='reviews font-Poppins text-[14px] py-4 w-full divide-y-2'>
        {fetchError && (<p className='text-red-600'>{fetchError}</p>)}
        <div>
          {reviews.map((review) => (
          <div className='py-3 w-fit' key={review.rating}>
            <h1 className='text-[16px]'>{review.name}</h1>
            <p className='py-1 italic'>{review.message}</p>
            <span className='flex items-center gap-1'>Rating: <IoMdStar className='text-[17px] text-TEXT'/> {review.rating}</span>
          </div>
          
        ))}
        </div>

        {user? <form action="" className='flex justify-center py-5 flex-col xl:w-[70%] lg:w-[70%] md:w-[70%] font-Poppins'>
        <h1 className='text-[17px] mb-3'>Tell us your experience</h1>
        <input type="text" placeholder='Enter name' required className='px-2 border-[2px] h-[40px] focus:border-TEXT focus:outline-none' id='name' onChange={(e) => setName(e.target.value)}/>
        <textarea name="message" id="message" cols="10" rows="4" placeholder='Your experience' required className='border-[2px] mt-4 px-2 resize-none focus:border-TEXT focus:outline-none' onChange={(e) => setMessage(e.target.value)}></textarea>
        <ul className='flex items-center gap-7 py-4'>
          <motion.li whileTap={{scale:1.2}}onClick={() => setRating(1)} className='flex items-center flex-col bg-white shadow-sm p-2 rounded-[4px] text-[16px] hover:bg-gray-100 cursor-pointer gap-1'>Bad<BsEmojiAngryFill className='text-[18px]'/></motion.li>
          <motion.li whileTap={{scale:1.2}}onClick={() => setRating(3)} className='flex items-center flex-col bg-white shadow-sm p-2 rounded-[4px] text-[16px] hover:bg-gray-100 cursor-pointer gap-1'>Average<BsFillEmojiExpressionlessFill className='text-[18px]'/></motion.li>
          <motion.li whileTap={{scale:1.2}}onClick={() => setRating(5)} className='flex items-center flex-col bg-white shadow-sm p-2 rounded-[4px] text-[16px] hover:bg-gray-100 cursor-pointer gap-1'>Very Good<BsEmojiHeartEyesFill className='text-[18px]'/></motion.li>
        </ul>
        {formError && (<p className='py-2 text-red-600'>{formError}</p>)}
        <motion.button whileTap={{scale:1.2}} className='xl:w-[200px] lg:w-[200px] md:w-[200px] uppercase text-center hover:bg-BG bg-black text-white' onClick={submitReview}>submit</motion.button>
      </form> : 
      <div className='py-8'>
        <h1 className='text-[16px]'>Please <Link to='/signIn' className='underline text-TEXT'>sign-in</Link> or <Link to='/Register' className='underline text-TEXT'>create an account</Link> to make review</h1>
      </div>
      }
      </div>
      }
      <div className='py-4 w-full'>
        <h1 className='text-[20px] font-Heading pb-4 text-TEXT'>You might also like</h1>
        <div className='flex items-center justify-center flex-col gap-5'>
        {result}
        </div>
      </div>
      
      <ToastContainer/>
    </motion.div>
  )
}

export default ApartmentsDetails