import React from 'react'
import { IoLocation } from "react-icons/io5";
import { FiMail, FiPhoneCall } from "react-icons/fi";

const Footer = () => {
  return (
    <div className='px-[1rem] xl:px-[3rem] lg:px-[3rem] py-8 bg-BG'>
    <footer className='flex justify-between px-[1rem] xl:px-[3rem] lg:px-[3rem] py-8 flex-col xl:flex-row lg:flex-row font-Poppins text-[14px] text-white w-[100%] gap-8 xl:gap-[8rem] lg:gap-[8rem]'>
      <div className='xl:w-[40%] lg:w-[40%]'>
        <h1 className='text-[20px] text-[#FF0000] font-Heading xl:pb-8 lg:pb-8 pb-3 font-semibold'>Mecs Daedal Canada Inc.</h1>
        <div>
          <p className='pb-5'>At Mecs Daedal Canada Inc., we recognize that our success is driven by the talent and dedication of our employees. We are always on the lookout for passionate individuals who are eager to contribute to our dynamic team. By joining us, you will become part of a company that values creativity, teamwork, and continuous growth.</p>
          <p></p>
        </div>
      </div>
      <div className='xl:w-[20%] lg:w-[20%]'>
        <h1 className='text-[20px] text-TEXT font-Heading xl:pb-8 pb-3 lg:pb-8 font-semibold'>Services</h1>
        <div>
          <ul className='flex gap-3 flex-col'>
            <li>Business Growth</li>
            <li>Sustainability</li>
            <li>Customer Insights</li>
            <li>Advanced analytics</li>
            <li>Performance</li>
          </ul>
        </div>
      </div>
      <div className='xl:w-[25%] lg:w-[25%]'>
        <h1 className='text-[20px] text-TEXT font-Heading xl:pb-8 pb-3 lg:pb-8 font-semibold'>Contact Info</h1>
        <div className='flex flex-col gap-3'>
          <p className='flex items-center gap-2'><IoLocation className='text-[20px]'/>1344 157 street SW, Edmonton, T6W4K7</p>
          <p className='flex items-center gap-2'><FiPhoneCall/><a href="tel:+17808071142" className='hover:text-TEXT'>+1(780)807-1142</a></p>
          <p className='flex items-center gap-2'><FiMail/><a href="mailto:admin@mecsdaedal.ca" className='hover:text-TEXT'>admin@mecsdaedal.ca</a></p>
        </div>
        {/* <ul className='flex items-center gap-3 pt-3'>
          <li><FaFacebookF/></li>
          <li><FaTwitter/></li>
          <li><FaInstagram/></li>
        </ul> */}
      </div>
      <div className='xl:w-[25%] lg:w-[25%]'>
        <h1 className='text-[20px] text-TEXT font-Heading xl:pb-8 pb-3 lg:pb-8 font-semibold'>Resources</h1>
        <ul>
          <li>Careers</li>
          <li>Contact Us</li>
          <li>Learning Center</li>
          <li>Resource Hub</li>
          <li>Support</li>
        </ul>
      </div>
    </footer>
    <div className='flex items-center justify-center text-center flex-wrap xl:flex-nowrap lg:flex-nowrap'>
      <h1 className='text-white'>All Right Reserved &copy; 2023 <span className='text-[#FF0000] text-[18px] font-Heading mr-8'>Mecs Daedal Canada Inc.</span></h1>
      {/* <span className='text-white font-Heading'>Design by: <a href="mailto:olagunjuolamideemmanuel@gmail.com" className='hover:text-TEXT'>Olagunju Olamide</a></span> */}
      </div>
    </div>
  )
}

export default Footer