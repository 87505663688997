import React from 'react'
import { BsCart3 } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import { useUserAuth } from '../context/userAuthContext';
import { MdOutlineCurrencyExchange } from "react-icons/md";
import { LiaBookOpenSolid } from "react-icons/lia";
import { Card, List, ListItem } from '@tremor/react';

const Admin = () => {
    const {Apartments, val, bookings, user} = useUserAuth()
    let total = 0
    bookings.forEach(item => {
        total += item.total
    })

    function classNames(...classes) {  return classes.filter(Boolean).join(' ');}
      
        const summary = 
      [{   
        name: 'Total Bookings ($)',    
        value: total,  
    },  
        {    
            name: 'Apartments',    
            value: Apartments.length,  
        },
        {    
            name: 'Users',    
            value: val.length,  
        },
        {    
            name: 'Number of Bookings',    
            value: bookings.length,  
        },
    
    ];
      const valueFormatter = (number) =>  `${Intl.NumberFormat('us').format(number).toString()}`;
      const statusColor = {  Organic: 'bg-blue-500',  Sponsored: 'bg-violet-500',};
    
    

  return (
    <div id='admin' className='font-Poppins'>
        <h1 className='text-[21px] pt-[1rem] font-Roboto'>Dashboard</h1>
        <div className='py-2'>
            <h1 className='flex items-center gap-2'>Welcome, <p className='text-TEXT '>{user.email}</p></h1>
        </div>
        <div className='w-full flex items-center gap-8 xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap'>
            <div className='flex items-center gap-[1.5rem] py-8 flex-wrap flex-col xl:w-[50%] lg:w-[50%] w-full'>
                <div className='flex items-center gap-4 py-5 px-8 bg-[#D8E990] rounded-[10px] w-full'>
                    <h1 className='p-2 bg-white rounded-full'><MdOutlineCurrencyExchange className='text-TEXT'/></h1>
                    <div className='flex gap-[15px] flex-col'>
                        <h1>Total Bookings</h1>
                        <span className='xl:text-[2rem] lg:text-2xl text-xl'>${total}</span>
                    </div>
                </div>
                
                <div className='flex items-center gap-4 py-5 px-6 bg-rose-400 rounded-[10px] w-full'>
                    <h1 className='p-2 bg-white rounded-full'><BsCart3 className='text-TEXT'/></h1>
                    <div className='flex gap-[15px] flex-col'>
                        <h1 className=''>Apartments</h1>
                        <span className='xl:text-[2rem] lg:text-2xl text-xl'>{Apartments.length}</span>
                    </div>
                </div>
                
                <div className='flex items-center gap-4 py-5 px-6 bg-[#015358] rounded-[10px] w-full'>
                    <h1 className='p-2 bg-white rounded-full'><LiaBookOpenSolid className='text-TEXT'/></h1>
                    <div className='flex gap-[15px] flex-col'>
                        <h1 className='text-white'>Bookings</h1>
                        <span className='xl:text-[2rem] lg:text-2xl text-xl text-white'>{bookings.length}</span>
                    </div>
                </div>
                <div className='flex items-center gap-4 py-5 px-6 bg-[#56B485] rounded-[10px] w-full'>
                    <h1 className='p-2 bg-white rounded-full'><FaUserCircle className='text-TEXT'/></h1>
                    <div className='flex gap-[15px] flex-col'>
                        <h1>Total Users</h1>
                        <span className='xl:text-[2rem] lg:text-2xl text-xl'>{val.length}</span>
                    </div>
                </div>
                
            </div>
            <div className='xl:w-[50%] lg:w-[50%] w-full bg-blue-300 rounded-[10px] mb-10'>
                <Card className="sm:mx-auto">        
                    <h3 className="font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong text-[19px] font-Poppins border-l-[4px] border-black pl-1 py-0 text-TEXT">Bookings metrics</h3>
                         
                            <List className="mt-2">          
                                {summary.map((item) => (            
                                    <ListItem key={item.name}>              
                                        <div className="flex items-center space-x-2">
                                            <span className={classNames(statusColor[item.name], 'h-0.5 w-3')}
                                            aria-hidden={true}/>                
                                            <span>{item.name}</span>              
                                        </div>              
                                        <span className="font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">                
                                            {valueFormatter(item.value)}
                                        </span>            
                                    </ListItem>          
                                    ))}        
                                </List>      
                            </Card>
                </div>
        </div>
    </div>
  )
}

export default Admin