import React, { useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import logo from '../images/MEDS-DAEDAL.png'
import { useReactToPrint } from 'react-to-print'
import { useUserAuth } from '../context/userAuthContext'
import moment from 'moment'


const ThankYou = () => {
  const{user} = useUserAuth()
  const location = useLocation()
  const {images,TotalAccFee,rating,numberOfGuest, title, dateDifference, formattedStartDate, formattedEndDate, firstName,lastName,email,phone, order, id} = location.state
  const bookingDate = moment().format('MMMM Do YYYY, h:mm:ss a')

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })
 
  return (
        <motion.div animate={{y:0, opacity: 1}} initial={{opacity: 0, y:100}} transition={{type: 'spring', stiffness: 100, duration: 2}} className='flex items-center justify-center w-full font-Poppins py-[2rem] xl:px-0 lg:px-0 px-[1rem]' id='receipt_bg' ref={componentRef}>
        <div className='flex justify-center py-4 flex-col  xl:w-[70%] lg:w-[70%] w-full'>
        <img src={logo} alt="mecs daedal" className='w-[100px]'/>
        <div className='flex items-center justify-between flex-wrap xl:flex-nowrap lg:flex-nowrap'>
          <h1 className='xl:text-4xl lg:text-4xl text-2xl flex items-center gap-3 text-TEXT pb-2'>INVOICE</h1>
          <p>{bookingDate}</p>
        </div>
          <hr />
          
          <div className='flex justify-between xl:flex-nowrap lg:flex-nowrap flex-wrap-reverse'>
            <div className='py-4 '>
              <h1 className='xl:text-[1.5rem] lg:text-[1.5rem] text-[1.3rem] uppercase'>{user.displayName}</h1>
              <p className='text-[15px]'>{user.email}</p>
            </div>
            <p className='pt-5 text-green-800 text-[15px] flex xl:items-end lg:items-end md:items-end items-start flex-col'>Transaction Reference <span className='text-[1.5rem]'>{order.id}</span></p>
          </div>
          <div className='py-3'>
          <h1 className='text-[17px] uppercase py-[6px] bg-TEXT text-white px-1 mb-2'>Payer Information</h1>
          <p className='capitalize my-1 flex items-center gap-5 bg-BG px-1 text-white py-[5px]'><span className='uppercase'>Name:</span> {firstName} {lastName}</p>
          <p className='bg-BG px-1 my-1 flex items-center gap-5 text-white py-[5px] overflow-x-auto'><span className='uppercase'>Email:</span> {email}</p>
          <p className='bg-BG px-1 my-1 flex items-center gap-5 text-white py-[5px]'><span className='uppercase'>Phone Number:</span> {phone}</p>
          </div>
          <hr />
          <h1 className='text-[17px] mt-5 mb-3 py-[6px] uppercase bg-TEXT text-white px-1'>Booking Information</h1>
          <div className='border-[2px] p-6 rounded-[5px] mb-6'>
            <div>
              <div className='flex items-center gap-3 mb-5 flex-wrap'>
                <img src={images[0]} alt="" className='xl:w-[300px] lg:w-[300px] md:w-[300px] sm:w-[300px] rounded-[10px]'/>
                <h1>{title}</h1>
              </div>
              <h1>Guests: {numberOfGuest}</h1>
              <div className='flex items-center justify-between flex-wrap'>
                <h1 className='flex items-center gap-3 xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap xl:py-0 lg:py-0 py-2'>Dates:<p className='text-TEXT underline'>{formattedStartDate}</p> - <p className='text-TEXT underline'>{formattedEndDate}</p></h1>
                <h1 className='flex items-center gap-2 xl:py-0 lg:py-0 py-2'>Amount Paid: <p className='text-[19px] text-TEXT'>${TotalAccFee}</p></h1>
              </div>
            </div>
            
          </div>
          <div className='border-t-2 py-4'>
            <p>Please send a copy of this receipt <a href="https://wa.me/+1(780)-807-1142" className='underline text-green-600'>here</a> for Confirmation! We appreciate your patronage</p>
            <p>Thank You!</p>
          </div>
          <div className='flex items-center gap-5'>
            <Link to={'/home'}><motion.button whileTap={{scale: 1.1}} className='my-7 bg-TEXT border-0 text-white rounded-[5px] hover:bg-BG'>Go Home</motion.button></Link>
            <motion.button whileTap={{scale: 1.1}} className='my-7 bg-transparent border-2 hover:text-white capitalize text-black rounded-[5px] hover:bg-BG' onClick={handlePrint}>print receipt</motion.button>
          </div>
        </div>
      </motion.div>
  )
}

export default ThankYou