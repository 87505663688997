import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useUserAuth } from '../context/userAuthContext'
import { motion } from 'framer-motion'
import { IoIosArrowBack } from "react-icons/io";
import PaystackPop from '@paystack/inline-js'

const Confirmation = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { user } = useUserAuth()
    const {images,TotalAccFee,rating,numberOfGuest, title, dateDifference, formattedStartDate, formattedEndDate, numberOfDays, price, TotalFee, status, id} = location.state
  return (
    <motion.div initial ={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} transition={{duration:1}} className='flex justify-center flex-col w-full font-Poppins xl:py-[5rem] lg:py-[5rem] xl:px-[10rem] lg:px-[10rem] px-4 py-10'>
      <h1 className='xl:text-3xl lg:text-3xl text-2xl flex items-center gap-3'><IoIosArrowBack className='text-TEXT cursor-pointer hover:text-BG' onClick={() => navigate(-1)}/>Confirm and pay</h1>
      <div className='flex justify-center py-10 gap-5 flex-wrap xl:flex-nowrap lg:flex-nowrap'>
        <div className='border-[2px] p-8 rounded-[5px] xl:w-[60%] lg:w-[60%] md:w-[60%] w-full'>
          <h1 className='text-2xl text-TEXT'>Your trip</h1>
          <div className='py-4 flex flex-col border-b-2'>
            <h1 className='text-[16px] pb-1'>Dates:</h1>
            <p className=' flex items-center gap-3 text-[15px] text-TEXT'>From: <span className='text-black'>{formattedStartDate}</span></p>
            <p className=' flex items-center gap-3 text-TEXT'>To: <span className='text-black'>{formattedEndDate}</span></p>
          </div>
          <div className='py-4'>
            <h1>Guests:</h1>
            <span className='text-TEXT'>{numberOfGuest}</span>
          </div>
        </div>
        <div className='border-[2px] p-5 rounded-[5px] xl:w-[40%] lg:w-[40%] md:w-[40%]'>
          <div className='flex items-center flex-row'>
            <div className=''>
              <div className='flex items-center gap-2 flex-wrap'>
                <img src={images[0]} alt="a" className='w-[300px] rounded-[5px]'/>
                <h1>{title}</h1>
              </div>
              <h1 className='text-xl py-6 text-TEXT'>Price details</h1>
              <div className='flex items-center justify-between py-2 border-b-[2px]'>
                <span>${price} x {dateDifference || numberOfDays} nights</span>
                <h1>${TotalFee}</h1>
              </div>
              <div className='flex items-center justify-between py-4'>
                <h1>Total:</h1>
                <span className='text-[20px]'>${TotalAccFee}</span>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      
      <motion.button whileTap={{scale: 1.1}}  className='w-[200px] py-4 bg-TEXT text-white hover:bg-black ml-auto' onClick={() => navigate('/Payment', {state: 
        {images,
          TotalAccFee,
          rating,
          numberOfGuest,
          title, 
          dateDifference,
          formattedStartDate,
          formattedEndDate,
          numberOfDays,
          price,
          TotalFee,
          id
          }})}>Confirm and pay</motion.button>
        
    </motion.div>
  )
}

export default Confirmation