import React from 'react'
import { useUserAuth } from '../context/userAuthContext'
import { Link } from 'react-router-dom'
import { MdVerified } from "react-icons/md";
import avatar from '../images/profile avatar.png'
import { FaUserLarge } from 'react-icons/fa6';


const Profile = () => {
    const {user} = useUserAuth()
  return (
    <>
    {user? 
    <div className='px-[1rem] xl:px-[3rem] lg:px-[3rem] xl:py-[3rem] py-[2rem] flex flex-col gap-8 font-Poppins'>
        <h1 className='text-2xl text-TEXT border-l-[3px] w-fit px-2 border-BG'>My Profile</h1>
        <div className='flex xl:justify-center lg:justify-center items-center basis-1/2 xl:gap-10 flex-wrap-reverse xl:flex-nowrap lg:flex-nowrap'>
          <div className='basis-1/2 xl:pb-0 lg:pb-0 pt-10'>
            <div className='flex flex-col pb-4'>
              <label htmlFor="" className='pb-2'>Your Name</label>
              <input type="text" value={user.displayName} disabled={user.displayName} placeholder={user.displayName || 'Enter Your Name'} className='border-2 py-2 px-1 text-gray-600 rounded-[5px]'/>
            </div>
            <div className='flex flex-col pb-4'>
              <label htmlFor="" className='pb-2'>Email Address</label>
              <input type="email" value={user.email} disabled={user.email} placeholder={user.email || 'Enter Your email address'} className='border-2 py-2 px-1 text-gray-600 rounded-[5px]'/>
            </div>
            <div className='flex flex-col pb-4'>
              <label htmlFor="" className='pb-2'>Phone Number</label>
              <input type="tel" value={user.phoneNumber} disabled={user.phoneNumber} placeholder={user.phoneNumber || 'Enter Your Phone Number'} className='border-2 py-2 px-1 text-gray-600 rounded-[5px]'/>
            </div>
          </div>
          <div className='basis-1/2 flex justify-center gap-3'>
            <img src={user.photoURL || avatar} alt={user.displayName || user.email} className='max-w-[200px] rounded-full max-h-[200px]'/>
            <span>{user.emailVerified? <MdVerified className='text-TEXT'/> : ''}</span>
          </div>
        </div>    
      </div> :
    <div className='h-[50vh] px-[1rem] xl:px-[3rem] lg:px-[3rem] py-4 flex items-center justify-center flex-col gap-4'>
        <FaUserLarge className='text-6xl'/>
        <h1>Please <Link to='/signIn' className='text-TEXT underline'>login</Link> to view Profile</h1>
    </div> }
    </>
  )
}

export default Profile