import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useUserAuth } from '../context/userAuthContext'
import RoomsCard from './RoomsCard'
import Error from '../images/not found.jpg'
import { motion } from 'framer-motion'



const SearchResults = ({placeholder}) => {
  const location = useLocation()
  const {result, range} = useUserAuth()
  // const [apartment, setApartment] = useState([])
  // const {selectionRange, handleSelect, cancelSearch, setNumberOfGuest} = useUserAuth()
  const {searchInput,endDate, startDate,numberOfGuest} = location.state

  // const formattedStartDate = format(new Date(startDate), 'dd MMMM yy')
  // const formattedEndDate = format(new Date(endDate), 'dd MMMM yy')
  
  // const range = `${formattedStartDate} to ${formattedEndDate}`
  

  return (
    <motion.div initial ={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} transition={{duration:1}} className='px-[1rem] xl:px-[3rem] lg:px-[3rem] py-[2rem] font-Poppins'>
      <input placeholder = {`${searchInput} | ${range} | ${numberOfGuest} guest`} disabled className='w-full flex items-center justify-center text-center pb-1'></input>
      <div className='border-b-2 pb-2'>
        <p className='text-[15px] text-gray-600'>Rooms in {searchInput} from <span className='text-TEXT px-2'>{range}</span> for {numberOfGuest} guests</p>
        <h1 className='xl:text-2xl lg:text-2xl text-[20px] text-TEXT capitalize py-1'>Apartments in {searchInput}</h1>
      </div>
      {result.length === 0 ? 
      <>
      <div className='flex items-center justify-center py-6 font-Poppins flex-col'>
        <h1 className='text-[16px]'>No result found</h1>
        <img src={Error} alt='not found' className='w-[22rem] xl:w-[42rem] lg:w-[40rem]'/>
      </div>

      <h1>You might also like</h1>
      <RoomsCard/>
      </>
      : <div className={`flex items-center justify-center flex-wrap py-[2rem] gap-[2rem]`}>
      {result}
      </div>}
    </motion.div>
  )
}

export default SearchResults