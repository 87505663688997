import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../components/Home'
import Apartments from '../components/Apartments'
import ApartmentsDetails from '../components/ApartmentsDetails'
import SignIn from '../components/SignIn'
import Register from '../components/Register'
import SearchResults from '../components/SearchResults'
import ThankYou from '../components/ThankYou'
import ScrollToTop from '../components/ScrollToTop'
import Logistics from '../logistics/Logistics'
import Bookings from '../components/Bookings'
import Search from '../components/Search'
import Confirmation from '../components/Confirmation'
import Payment from '../components/paystack/Payment'
import Real_Estate from '../components/Real_Estate'
import Contact from '../components/Contact'
import PayPal from '../components/paystack/PayPal'
import User from '../pages/User'
import Orders from '../pages/Orders'
import Dashboard from '../components/Dashboard'
import ApartmentLists from '../pages/ApartmentLists'
import Admin from '../pages/Admin'
import Layout from '../pages/Layout'
import RequireAuth from '../components/RequireAuth'
import { useUserAuth } from '../context/userAuthContext'
import Profile from '../components/Profile'



const Router = () => {
  const {status, setStatus} = useUserAuth()
  return (
    <>
    <ScrollToTop/>
    <Routes>
        <Route path='' element={<Layout/>}>
          <Route path='/' element={<Home/>}/>
          <Route path='/home' element={<Home/>}/>
          <Route path='/bookings' element={<Bookings/>}/>
          <Route path='/logistics' element={<Logistics/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/real_estate' element={<Real_Estate/>}/>
          <Route path='/signIn' element={<SignIn/>}/>
          <Route path='/register' element={<Register/>}/>
          <Route path='/searchResults' element={<SearchResults/>}/>
          <Route path='/apartments' element={<Apartments/>}/>
          <Route path='/search' element={<Search/>}/>

          <Route element={<RequireAuth />}>
            <Route path='/paypal' element={<PayPal/>}/>
            <Route path='/apartments/:id' element={<ApartmentsDetails status={status} setStatus ={setStatus}/>}/>
            <Route path='/confirmation' element={<Confirmation/>}/>
            <Route path='/profile' element={<Profile/>}/>
            <Route path='/payment' element={<Payment/>}/>
            <Route path='/thankyou' element={<ThankYou/>}/>
            <Route path='/dashboard' element={<Dashboard/>}>
                <Route path='/dashboard' element={<Admin/>}/>
                <Route path='/dashboard/orders' element={<Orders/>}/>
                <Route path='/dashboard/user' element={<User/>}/>
                <Route path='/dashboard/apartmentLists' element={<ApartmentLists status={status} setStatus ={setStatus}/>}/>
            </Route>
          </Route>
        </Route>
        
    </Routes>
    </>
  )
}

export default Router