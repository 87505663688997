import React from 'react'
import log1 from '../images/log4.jpg'
import log2 from '../images/log2.jpg'
import log3 from '../images/log3.jpg'
import bg1 from '../images/bg-1.jpg'
import bg2 from '../images/bg-7.jpg'
import bg6 from '../images/bg-6.jpg'
import ExperienceCard from './ExperienceCard'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import logo from '../images/MEDS-DAEDAL.png'

const Home = () => {
  return (
    <motion.main animate={{y:0, scale: 1}} initial={{scale: 1, y:100}} transition={{type: 'spring', stiffness: 150}} id='main-bg' className='px-[1rem] xl:px-[3rem] lg:px-[3rem]'>
      <div className='xl:py-[5rem] lg:py-[5rem] py-[3rem] flex items-center justify-between w-full xl:flex-row lg:flex-row flex-col gap-5'>
        <div className='xl:w-[50%] lg:w-[40%]'>
          <img src={logo} alt="" className='w-[250px]'/>
          <h1 className='font-Poppins text-[20px] flex items-center gap-2 flex-wrap'>Welcome to <p className='text-[#FF0000]'>Mecs Daedal Canada Inc.</p></h1>
          <h1 className='font-Heading text-[3rem] text-TEXT capitalize py-5'>Your Gateway to Exceptional Service!</h1>
          <p className='text-BG font-Poppins px-2 py-2 leading-[1.8rem]'>At Mecs Daedal Canada Inc., our goal is to redefine the logistics landscape. We aim to consistently provide innovative, reliable, and cost-effective solutions that exceed our clients' expectations. By leveraging cutting-edge technology and a highly skilled workforce, we aspire to be the preferred logistics partner, setting new standards of efficiency and reliability in the industry.</p>
        </div>
        <div className='flex xl:items-center lg:items-center items-start justify-around xl:w-[50%] lg:w-[50%] gap-2 pt-6 xl:pt-0 lg:pt-0'>
          <img src={log3} alt="" className='xl:w-[230px] sm:w-[200px] w-[110px] lg:w[230px] lg:h-[400px] h-[300px]  xl:h-[410px] object-cover object-center '/>
          <img src={log2} alt="" className='xl:w-[230px] sm:w-[200px] w-[110px] lg:w[230px] lg:h-[400px] h-[300px] xl:h-[410px] object-cover object-center '/>
          <img src={log1} alt="" className='xl:w-[230px] sm:w-[200px] w-[110px] lg:w[230px] lg:h-[400px] h-[300px] xl:h-[410px] object-cover object-center'/>
        </div>
      </div>
      <div className='flex items-center justify-center flex-col'>
        <p className='font-Heading text-[17px] text-TEXT'>what we do</p>
        <h1 className='text-[27px] font-Poppins pb-8'>Our services</h1>
        <div className='flex justify-center flex-col flex-wrap xl:gap-[4rem] lg:gap-[4rem] md:gap-[3rem] py-8'>
          <div className='flex xl:justify-center lg:justify-center w-full gap-8 flex-wrap mb-6 xl:mb-0 lg:mb-0 xl:h-[50vh] lg:h-[50vh] md:h-[50vh]'>
            <div className='xl:w-[50%] lg:w-[50%] md:w-[50%] flex justify-between flex-col xl:h-[50vh] lg:h-[50vh] md:h-[50vh]'>
              <div>
                <h1 className='text-[23px] text-TEXT font-Heading pb-4'>Logistics</h1>
                <h1 className='text-[16px] font-Poppins'>We aim to consistently provide innovative, reliable, and cost-effective solutions that exceed our clients' expectations. By leveraging cutting-edge technology and a highly skilled workforce, we aspire to be the preferred logistics partner, setting new standards of efficiency and reliability in the industry.</h1>
              </div>
              <Link to='/logistics'><motion.button whileTap={{scale: 1.1}} className='py-3 bg-BG text-white font-Poppins border-0 hover:bg-TEXT hidden xl:block lg:block md:block'>Explore</motion.button></Link>
            </div>
            <img src={bg2} alt="" className='xl:w-[40%] lg:w-[40%] md:w-[40%] rounded-md xl:h-[50vh] lg:h-[50vh] md:h-[50vh]'/>
            <Link to='/logistics'><motion.button whileTap={{scale: 1.1}} className='py-3 bg-BG text-white font-Poppins border-0 hover:bg-TEXT block xl:hidden lg:hidden md:hidden'>Explore</motion.button></Link>
          </div>
          <div className='flex xl:justify-center lg:justify-center justify-end flex-row-reverse gap-8 flex-wrap py-8 xl:h-[50vh] lg:h-[50vh] md:h-[50vh]'>
            <div className='flex flex-col xl:w-[50%] lg:w-[50%] md:w-[50%] justify-between xl:h-[50vh] lg:h-[50vh] md:h-[50vh]'>
            <div>
              <h1 className='text-[23px] text-TEXT font-Heading pb-4 '>Accommodation</h1>
              <h1 className=' text-[16px] font-Poppins'>We redefine the concept of temporary accommodation by offering luxurious houses and apartments for short lets, Airbnb, and other home-away-from-home experiences. Whether you are traveling with family, on a business trip, or simply exploring a new city, we provide a seamless blend of comfort, convenience, and elegance to make your stay memorable.</h1>
            </div>
            <Link to='/bookings'><motion.button whileTap={{scale: 1.1}} className='py-3 bg-BG text-white font-Poppins border-0 hover:bg-TEXT hidden xl:block lg:block md:block'>Explore</motion.button></Link>
            </div>
            <img src={bg1} alt="" className='xl:w-[40%] lg:w-[40%] md:w-[40%] rounded-md xl:h-[50vh] lg:h-[50vh] md:h-[50vh]'/>
            <Link to='/bookings'><motion.button whileTap={{scale: 1.1}} className='py-3 bg-BG text-white font-Poppins border-0 hover:bg-TEXT block xl:hidden lg:hidden md:hidden'>Explore</motion.button></Link>
          </div>
          <div className='flex xl:justify-center lg:justify-center w-full gap-8 flex-wrap mb-6 xl:mb-0 lg:mb-0 xl:h-[50vh] lg:h-[50vh] md:h-[50vh] py-8'>
            <div className='xl:w-[50%] lg:w-[50%] md:w-[50%] flex justify-between flex-col xl:h-[50vh] lg:h-[50vh] md:h-[50vh]'>
              <div>
                <h1 className='text-[23px] text-TEXT font-Heading pb-4'>Real Estate</h1>
                <h1 className='text-[16px] font-Poppins'>We are more than just a real estate and planning company - we are planners of your dreams, transforming spaces into thriving communities. With a wealth of experience and a commitment to innovation, we specialize in real estate development and urban planning, creating environments that inspire and endure.</h1>
              </div>
              <Link to='/real_estate'><motion.button whileTap={{scale: 1.1}} className='py-3 bg-BG text-white font-Poppins border-0 hover:bg-TEXT hidden xl:block lg:block md:block'>Explore</motion.button></Link>
            </div>
            <img src={bg6} alt="" className='xl:w-[40%] lg:w-[40%] md:w-[40%] rounded-md xl:h-[50vh] lg:h-[50vh] md:h-[50vh]'/>
            <Link to='/real_estate'><motion.button whileTap={{scale: 1.1}} className='py-3 bg-BG text-white font-Poppins border-0 hover:bg-TEXT block xl:hidden lg:hidden md:hidden'>Explore</motion.button></Link>
          </div>
        </div>
      </div>
      <ExperienceCard/>
    </motion.main>
  )
}

export default Home