import React from 'react'
import { useUserAuth } from '../context/userAuthContext'


const ApartmentLists = () => {
  const {Apartments, status} = useUserAuth()
  console.log(status);
  return (
    <div id='lists' className='font-Poppins pb-10 overflow-auto'>
      <h1 className='py-4 text-[20px] font-Roboto'>Apartment Lists</h1>
            <table className='w-full'>
              <thead className='bg-gray-100'>
                <tr>
                  <th className='text-left font-normal p-2 text-sm'>#</th>
                  <th className='text-left font-normal p-2 text-sm'>Photo</th>
                  <th className='text-left font-normal p-2 text-sm'>Title</th>
                  <th className='text-left font-normal p-2 text-sm'>Price</th>
                  <th className='text-left font-normal p-2 text-sm'>Ratings</th>
                  <th className='text-left font-normal p-2 text-sm'>Status</th>
                </tr>
              </thead>
              {Apartments.map((item, index) => (
              <tbody key={index} >
                <tr className='overflow-auto'>
                  <td className='py-3 px-2 text-sm'>{item.id}.</td>
                  <td className='py-3 px-2 text-sm'><img src={item.images[0]} alt={item.title} className='w-[100px] rounded-[5px]'/></td>
                  <td className='py-3 px-2 text-sm whitespace-nowrap'><h1 className='capitalize'>{item.title}</h1></td>
                  <td className='py-3 px-2 text-sm'><h1>${item.price}.00</h1></td>
                  <td className='py-3 px-2 text-sm'><h1>{item.averageRating}</h1></td>
                  <td className='py-3 px-2 text-sm'><h1 className={`${ status === Apartments.status ? 'bg-[#FF0000]' : 'bg-green-500'} p-2 rounded-[5px] w-[110px] text-center text-white uppercase`}>{status === Apartments.status ? 'Booked' : 'Available'}</h1></td>
                </tr>
              </tbody>))}
            </table>    
            {/* <button className='ml-auto my-8 px-2 bg-black hover:bg-TEXT text-white rounded-[5px]'>Add Apartment</button> */}
    </div>
  )
}

export default ApartmentLists