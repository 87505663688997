import React from 'react'
import RoomFewList from './RoomFewList'
import ExperienceCard from './ExperienceCard'
import bg_1 from '../images/bg-1.jpg'
import bg_2 from '../images/bg-2.jpg'
import bg_3 from '../images/bg-3.jpg'
import bg_4 from '../images/bg_4.jpg'
import bg_5 from '../images/bg_5.jpg'
import ServiceList from './ServiceList'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import {BiRightArrowAlt} from 'react-icons/bi'



const Bookings = () => {
  return (
    <>
    <motion.div initial ={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} transition={{duration:2}} className='px-[1rem] xl:px-[3rem] lg:px-[3rem] xl:py-[10rem] lg:py-[10rem] py-[6rem] flex items-center justify-center w-full flex-col gap-5'>
        <div className='xl:w-[60%] lg:w-[60%] flex items-center flex-col text-center'>
          <motion.h1 animate={{y: 0}} initial={{y:100}} transition={{delay: 1.0, type: 'spring', stiffness: 100}}  className='font-Poppins text-[24px] tracking-widest'>Welcome to Grand Castle Investment Ltd. </motion.h1>
          <motion.h1 animate={{opacity:1}} initial={{opacity:0}} transition={{delay: 2.0, type: 'spring', stiffness: 150}} className='font-Poppins text-[3rem] text-TEXT capitalize py-5'>Your Gateway to Exceptional Short-Term Stays!</motion.h1>
          <motion.p animate={{opacity: 1}} initial={{opacity: 0}} transition={{delay: 2.5, duration: 1.5, stiffness: 200}} className='text-BG font-Poppins px-2 py-2 leading-[1.8rem]'>At Grand Castle Suites, we redefine the concept of temporary accommodation by offering luxurious houses and apartments for short lets, Airbnb, and other home-away-from-home experiences. Whether you are traveling with family, on a business trip, or simply exploring a new city, we provide a seamless blend of comfort, convenience, and elegance to make your stay memorable.</motion.p>
        </div>
        <div className='flex items-baseline justify-center gap-2 pt-[2rem] xl:h-[80vh] lg:h-[80vh] md:h-[60vh] h-[55vh] overflow-hidden'>
          <motion.img animate={{y:0}} initial={{y: -50}} transition={{delay: 1, type: 'spring', stiffness: 100}} src={bg_4} alt="" className='xl:w-[230px] sm:w-[200px] w-[120px] lg:w[230px] lg:h-[70%] h-[70%]  xl:h-[70%] object-cover object-center rounded-[15px] hidden xl:block lg:block hover:scale-50'/>
          <motion.img animate={{y:0}} initial={{y:50}} transition={{delay: 2, type: 'spring', stiffness: 50}} src={bg_3} alt="" className='xl:w-[230px] sm:w-[200px] w-[120px] lg:w[230px] lg:h-[85%] h-[85%] xl:h-[85%] object-cover object-center rounded-[15px]'/>
          <motion.img animate={{y:0}} initial={{y:50}} transition={{delay: 2, type: 'spring', stiffness: 50}} src={bg_2} alt="" className='xl:w-[230px] sm:w-[200px] w-[120px] lg:w[230px] lg:h-full h-full xl:h-full object-cover object-center rounded-[15px] '/>
          <motion.img animate={{y:0}} initial={{y: -50}} transition={{delay: 1, type: 'spring', stiffness: 100}} src={bg_1} alt="" className='xl:w-[230px] sm:w-[200px] w-[120px] lg:w[230px] lg:h-[85%] h-[85%]  xl:h-[85%] object-cover object-center rounded-[15px]'/>
          <motion.img animate={{opacity: 1}} initial={{opacity:0}} transition={{delay: 1.5, type: 'spring', stiffness: 150}} src={bg_5} alt="" className='xl:w-[230px] sm:w-[200px] w-[120px] lg:w[230px] lg:h-[70%] h-[70%] xl:h-[70%] object-cover object-center rounded-[15px] hidden xl:block lg:block'/>
        </div>
        <Link to='/Apartments'><button className='bg-black  font-Poppins hover:bg-TEXT text-white border-0 flex items-center gap-4 py-3 mt-[3rem] text-[17px] rounded-md'>Explore<BiRightArrowAlt className='text-[20px] animate-ping transition ease-in-out'/></button></Link>
      </motion.div>
      {/* <Search/> */}
      <ServiceList/>
      <RoomFewList/>
      <ExperienceCard/>
      </>
  )
}

export default Bookings