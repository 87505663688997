import React from 'react'
import { Link } from 'react-router-dom'
import {BiRightArrowAlt} from 'react-icons/bi'


const RoomFewList = () => {
    // const locationDetails = [
    //     {
    //         img: london,
    //         location: 'London'
    //     },
    //     {
    //         img: newYork,
    //         location: 'New York'
    //     },
    //     {
    //         img: paris,
    //         location: 'Paris'
    //     },
    //     {
    //         img: canada,
    //         location: 'Canada'
    //     },
    //     {
    //         img: moscow,
    //         location: 'Moscow'
    //     },





    // ]


  return (
    <div className='px-[1rem] xl:px-[3rem] lg:px-[3rem] flex items-center justify-center flex-col text-center'>
        <p className='text-[15px] font-Heading text-TEXT'>Explore</p>
        <h1 className='text-[30px] font-Poppins'>stay anywhere</h1>
        <p className='xl:w-[50%] lg:w-[50%] font-Poppins text-[15px] py-3'>Whether you are traveling with family, on a business trip, or simply exploring a new city, we provide a seamless blend of comfort, convenience, and elegance to make your stay memorable.</p>
        {/* <div className='flex py-[3rem] gap-8 font-Poppins w-full overflow-x-scroll scrollbar-hide'>
            {locationDetails.map(({img,location,index}) => (
                <div key={index}>
                    <div className='flex items-center justify-center w-[330px] flex-col p-3 border-[1px] rounded-[10px]'>
                    <img src={img} alt={location} className='mb-[5px] w-full h-[250px] object-fill object-center rounded-lg'/>
                        <div className='flex items-center justify-between w-[100%] py-2 text-[15px]'>
                            <h1 className='flex items-center gap-1'><MdLocationOn className='text-TEXT text-[20px]'/>{location}</h1>
                        </div>
                    </div>
                </div>                   
            ))}
        </div> */}
        <Link to='/Apartments'><button className='bg-black  font-Poppins hover:bg-TEXT text-white border-0 flex items-center gap-2 py-3'>Explore<BiRightArrowAlt className='text-[17px]'/></button></Link>
    </div>
  )
}

export default RoomFewList